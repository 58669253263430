
import { Vue, Options } from "vue-class-component";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";import LabService from "../service/LabService";
import Confirmation from "../components/Confirmation.vue";
import { camelCase } from "lodash";

@Options({
    components: {
        Confirmation
    }
})
export default class OrderedLab extends Vue {
    private recordList = [];
    private recordID = 0;
    private pService;
    private toast;
    private vuexStore = useStore();
    private componentName = "";


    created() {
        this.pService = new LabService();
        this.toast = new Toaster();
    }

    mounted() {
        this.loadList();
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }


    loadList() {
        this.pService.getOrderedRequests(this.patientID).then(data => {
            const res = this.camelizeKeys(data);
            this.recordList = res.record;
        });
    }

    camelizeKeys = obj => {
        if (Array.isArray(obj)) {
            return obj.map(v => this.camelizeKeys(v));
        } else if (obj !== null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.camelizeKeys(obj[key])
                }),
                {}
            );
        }
        return obj;
    };

   

    deleteItem(id) {
        this.vuexStore.dispatch(
            ActionTypes.GET_RECEIPT_TITLE,
            "Are you sure to delete this"
        );
        this.componentName = "Confirmation";
        this.recordID = id;
    }

    updateConfirmation(b) {
        this.componentName = "";
        if (b.result) {
            this.pService.deleteOrderedRequest(this.recordID).then(res => {
                this.toast.handleResponse(res);
                this.loadList();
            });
        }
    }
}
