
import { Options, mixins } from "vue-class-component";
import UtilityOptions from "../../mixins/UtilityOptions";

export default class Report extends mixins(UtilityOptions) {
  private home = { icon: "pi pi-home", to: "/" };
  private items = [{ label: "Report" }];
  private reportList = [
    {
      label: "Visit Report",
      image: require("@/assets/menu-icons/employee.png").default,
      to: "/store/visit-reports",
      description:"All the visits for branch with different visit types.",
    },
    {
      label: "Patient Report",
      image: require("@/assets/menu-icons/employee.png").default,
      to: "/store/patient-report",
      description:"All the available patients in single branch with the basic details.",
    },
    {
      label: "User Report",
      image: require("@/assets/menu-icons/employee.png").default,
      to: "/store/user-report",
      description:"Users record with there roles based on user branch.",
    },
    {
      label: "Child Monitoring Report",
      image: require("@/assets/menu-icons/employee.png").default,
      to: "/store/child-monitoring-report",
      description:"All the activity of child monitoring with patient immunization based on patient records.",
    },
    {
      label: "Vaccination Report",
      image: require("@/assets/menu-icons/employee.png").default,
      to: "/store/vaccine-report",
      description:"Vaccine used in single branch with patient details.",
    },
    {
      label: "Medication Report",
      image: require("@/assets/menu-icons/employee.png").default,
      to: "/store/medication-report",
      description:"All the medicines prescribed ro patients with there timings.",
    },
    {
      label: "Receive/Return Stock Report",
      image: require("@/assets/menu-icons/employee.png").default,
      to: "/store/purchasing-report",
      description:"Vaccine and immunization received and refund description branch wise.",
    },
    {
      label: "Stock Report",
      image: require("@/assets/menu-icons/employee.png").default,
      to: "/store/stock-report",
      description:"Available stock of vaccines and medicines in single branch.",
    },
    {
      label: "Stock Alert Report",
      image: require("@/assets/menu-icons/employee.png").default,
      to: "/store/stock-alert-report",
      description:"Stock with is less then its minimum level branch wise.",
    },
    {
      label: "Activity Report",
      image: require("@/assets/menu-icons/employee.png").default,
      to: "/store/activity-report",
      description:"User log in and log out timings with there role branch wise.",
    },
    {
      label: "Stock Expiry Report",
      image: require("@/assets/menu-icons/employee.png").default,
      to: "/store/expiry-report",
      description:"User log in and log out timings with there role branch wise.",
    }
  ];
}
