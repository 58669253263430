
import { Options, mixins } from "vue-class-component";
import ChildMonitorService from "../../service/ChildMonitorService.js";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";
import UtilityOptions from "../../mixins/UtilityOptions";

@Options({
  components: {},
})
export default class ChildMonitor extends mixins(UtilityOptions) {
  private lists = [];
  private dialogTitle;
  private toast;
  private goToFirstLink = 0;
  private childService;
  private productDialog = false;
  private submitted = false;
  private statusDialog = false;
  private checkPagination = true;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Child Monitor" },
  ];

  private item = {
    id: 0,
    monthName: 'Month 0',
    optionType: 'Eyesight',
    status: "Active",
  };

  private state = reactive({
    questionName: "",
  });

  private validationRules = {
    questionName: {
      required,
    },
  };

  private v$ = useVuelidate(this.validationRules, this.state);

  private childTypes = [
    { key: "Eyesight" },
    { key: "Hearing" },
    { key: "General Child Info" },
  //  { key: "Immunization" }
  ];

  private monthTypes = [
    {key: 'Risk Factor'},
    {key: 'Month 0'},
    {key: 'Month 2'},
    {key: 'Month 3'},
    {key: 'Month 4'},
    {key: 'Month 6'},
    {key: 'Month 9'},
    {key: 'Month 12'},
    {key: 'Month 15'},
    {key: 'Month 18'},
    {key: 'Year 3-5'}
  ];

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.childService = new ChildMonitorService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.state.questionName = "";
    this.item = {
      id: 0,
      optionType: this.item.optionType,
      monthName: this.item.monthName,
      status: "Active",
    };

    this.submitted = false;
    this.dialogTitle = "Add New Option";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(isFormValid) {
    this.submitted = true;
    if (isFormValid) {
      if (this.item.id != 0) {
        this.childService
          .updateItem(this.item, this.state.questionName)
          .then((res) => {
            this.loadList(this.goToFirstLink);
            //SHOW NOTIFICATION
            this.toast.handleResponse(res);
          });
      } else {
        this.childService
          .saveItem(this.item, this.state.questionName)
          .then((res) => {
            this.goToFirstLink = 0;
            this.loadList(this.goToFirstLink);
            //SHOW NOTIFICATION
            this.toast.handleResponse(res);
          });
      }

      this.productDialog = false;
      this.item.id = 0;
      this.item.status = "";
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Option";
    this.productDialog = true;

    this.childService.getItem(data).then((res) => {
      if (res.length > 0) {
        this.state.questionName = res[0].question;
        this.item.status = res[0].status;
        this.item.optionType = res[0].type;
        this.item.monthName = res[0].month_name;
        this.item.id = res[0].id;
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data) {
    this.item.id = data.id;
    this.state.questionName = data.option_name;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.item.status = "Delete";
    this.childService.changeStatus(this.item).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.childService.getItems(this.item.optionType, page).then((data) => {
      this.lists = data.records;
      this.totalRecords = data.totalRecords;
      this.limit = data.limit;
    });
  }
}
