
import { Options, Vue } from "vue-class-component";
import { useStore } from "../store";

@Options({
    emits: ["updateConfirmationStatus"],
    components: {}
})
export default class Confirmation extends Vue {

    private statusDialog = true;
    private result = false;
    private vuexStore = useStore();

    //CLOSE THE ITEM DAILOG BOX
    closeDialog() {
        this.$emit("updateConfirmationStatus", {
            result: this.result
        });
    }

    get dialogTitle()
    {
        return this.vuexStore.getters.getReceiptTitle;
    }

    sendOption(r) {
        this.result = r;
        this.closeDialog();
    }
}
