
import { Options, mixins } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import PatientService from "../service/PatientService";
import Assessments from "../hospital_components/Assessments.vue";
import Consultation from "../hospital_components/Consultation.vue";
import Diagnosis from "../hospital_components/Diagnosis.vue";
import GeneralInfo from "../hospital_components/GeneralInfo.vue";
import Medications from "../hospital_components/Medications.vue";
//import NextVisit from "../hospital_components/NextVisit.vue";
import Observations from "../hospital_components/Observations.vue";
import PatientDocs from "../hospital_components/PatientDocs.vue";
import Referral from "../hospital_components/Referral.vue";
import LabOrders from "../hospital_components/LabOrders.vue";
import OrderedLab from "../hospital_components/OrderedLab.vue";
import Vaccination from "../hospital_components/Vaccination.vue";
import FamilyHistory from "../hospital_components/FamilyHistory.vue";
import ChildMonitoring from "../hospital_components/ChildMonitoring.vue";
import RiskFactor from "../hospital_components/RiskFactor.vue";
import HelpDoc from "../hospital_components/HelpDoc.vue";
import LegacyNotes from "../hospital_components/LegacyNotes.vue";
import { useStore, ActionTypes } from "../store";
import moment from "moment";
import UtilityOptions from "../mixins/UtilityOptions";
import PatientDialog from "../components/PatientDialog.vue";

@Options({
  emits: ["closeConsultantScreenEvent"],
  components: {
    Assessments,
    Consultation,
    Diagnosis,
    GeneralInfo,
    Medications,
    // NextVisit,
    FamilyHistory,
    ChildMonitoring,
    RiskFactor,
    Vaccination,
    Observations,
    PatientDocs,
    Referral,
    LabOrders,
    OrderedLab,
    HelpDoc,
    LegacyNotes,
    PatientDialog,
  },
})
export default class PatientConsultancy extends mixins(UtilityOptions) {
  private activeTab = 0;
  private activeLabTab = 0;
  private patientService;
  private productDialog = true;
  private instructionDialog = false;
  private submitted = false;
  private dialogTitle = "";
  private componentName = "";
  private toast;
  private patientType = "";
  private vuexStore = useStore();

  created() {
    this.patientService = new PatientService();
    this.toast = new Toaster();
  }

  mounted() {
    this.loadList();
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  get patientID() {
    return this.vuexStore.getters.getReceiptID;
  }

  closeDialog() {
    this.productDialog = false;
    this.submitted = false;
    this.$emit("closeConsultantScreenEvent", []);
  }

  get tabComponentName() {
    const tab = this.activeTab;

    let componentName = "";

    if (tab == 0) {
      componentName = "GeneralInfo";
    } else if (tab == 2) {
      componentName = "Assessments";
    } else if (tab == 3) {
      componentName = "Observations";
    }
    // else if (tab == 10) {
    //     componentName = "Diagnosis";
    // }
    else if (tab == 5) {
      componentName = "FamilyHistory";
    } else if (tab == 6) {
      componentName = "Consultation";
    } else if (tab == 10 && this.activeLabTab == 0) {
      componentName = "LabOrders";
    } else if (tab == 10 && this.activeLabTab == 1) {
      componentName = "OrderedLab";
    } else if (tab == 9) {
      componentName = "Medications";
    } else if (tab == 8) {
      componentName = "Vaccination";
    } else if (tab == 11) {
      componentName = "Referral";
    } else if (tab == 4) {
      componentName = "RiskFactor";
    } else if (tab == 7) {
      componentName = "ChildMonitoring";
    } else if (tab == 1) {
      componentName = "PatientDocs";
    } else if (tab == 13) {
      componentName = "HelpDoc";
    } else if (tab == 12) {
      componentName = "LegacyNotes";
    }

    return componentName;
  }

  patientAge(dob) {
    let dt = dob;

    let age = "";

    let bY = Number(moment(dt).format("YYYY"));
    let bM = Number(moment(dt).format("MM"));
    let bD = Number(moment(dt).format("DD"));

    let tY = Number(moment().format("YYYY"));
    let tM = Number(moment().format("MM"));
    let tD = Number(moment().format("DD"));

    let year = tY - bY;

    if (year <= 1) {
      year = 0;
    }

    age += year + " Yrs ";

    if (tM < bM) {
      age += tM - bM + 12 + " Months ";
      tY = tY - 1;
    } else {
      age += tM - bM + " M ";
    }

    if (tD < bD) {
      age += tD - bD + 30 + " Days ";
      tM = tM - 1;
    } else {
      age += tD - bD + " Days ";
    }

    return age;
  }

  loadList() {
    this.patientService.getItem(this.patientID).then((data) => {
      const patientInfo = data[0];

      this.patientType = patientInfo.patient_type;

      this.dialogTitle =
        patientInfo.first_name +
        " | SEX : " +
        patientInfo.gender +
        " | Type : " +
        patientInfo.patient_type +
        " | Age : " +
        this.patientAge(patientInfo.dob) +
        " | PHONE NO :  " +
        patientInfo.phone_no;
    });
  }

  updatePatientStatus(obj) {
    this.componentName = "";
  }

  editPatient() {
    if (this.patientID == "") {
      this.toast.showError("Patient ID is Missing");
    } else {
      this.componentName = "PatientDialog";
      this.store.dispatch(ActionTypes.GET_RECEIPT_ID, String(this.patientID));
    }
  }
}
