import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';

export default class PatientService {
	getItems(keyword,start) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = '/api/patient_list';
		const formData = new FormData();
		formData.append('keyword', keyword);
		formData.append('start', start);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
		.catch((e) => ExceptionHandling.HandleErrors(e))
		.finally(() => {
			store.dispatch(ActionTypes.PROGRESS_BAR, false);
		})
	}

	saveItem(stateObj,postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = '/api/add_patient';
		const formData = new FormData();
		formData.append('first_name',stateObj.firstName);
		formData.append('patient_type',stateObj.patientType);
		formData.append('gender',stateObj.gender);
		formData.append('phone_no',stateObj.phoneNo);
		formData.append('dob',stateObj.dob);
		formData.append('siblings',stateObj.siblings);
		formData.append('birth_order',stateObj.birthOrder);
		formData.append('secondary_phone',postObj.secondaryPhone);
		formData.append('blood_type',stateObj.bloodType);
		formData.append('city',stateObj.city);
		formData.append('district',stateObj.district);
		formData.append('birth_placetwo',postObj.birthPlacetwo);
		formData.append('birth_placethree',postObj.birthPlacethree);
		formData.append('birth_placefour',postObj.birthPlacefour);
		formData.append('birth_placefive',postObj.birthPlacefive);
		formData.append('birth_placesix',postObj.birthPlacesix);
		formData.append('responsibility_two',postObj.responsibilityTwo);
		formData.append('responsibility_three',postObj.responsibilityThree);
		formData.append('responsibility_four',postObj.responsibilityFour);
		formData.append('responsibility_five',postObj.responsibilityFive);
		formData.append('responsibility_six',postObj.responsibilitySix);
		formData.append('birth_resulttwo',postObj.birthResulttwo);
		formData.append('birth_resultthree',postObj.birthResultthree);
		formData.append('birth_resultfour',postObj.birthResultfour);
		formData.append('birth_resultfive',postObj.birthResultfive);
		formData.append('birth_resultsix',postObj.birthResultsix);
		formData.append('mother_name',postObj.motherName);
		formData.append('house_no',postObj.houseNo);
		formData.append('nearest_point',postObj.nearestPoint);
		formData.append('your_profession',postObj.yourProfession);
		formData.append('your_education',postObj.yourEducation);
		formData.append('husbands_name',postObj.husbandsName);
		formData.append('husbands_profession',postObj.husbandsProfession);
		formData.append('husband_education',postObj.husbandEducation);
		formData.append('next_kin',postObj.nextKin);
		formData.append('relationship',postObj.relationship);
		formData.append('phone_kin',postObj.phoneKin);
		formData.append('address_kin',postObj.addressKin);
		formData.append('age_married',postObj.ageMarried);
		formData.append('preg_lengthone',postObj.pregLengthone);
		formData.append('preg_lengthtwo',postObj.pregLengthtwo);
		formData.append('preg_lengththree',postObj.pregLengththree);
		formData.append('preg_lengthfour',postObj.pregLengthfour);
		formData.append('preg_lengthfive',postObj.pregLengthfive);
		formData.append('preg_lengthsix',postObj.pregLengthsix);
		formData.append('date_marriage',postObj.dateMarriage);
		formData.append('couple_related',postObj.coupleRelated);
		formData.append('birth_datetwo',postObj.birthDatetwo);
		formData.append('birth_datethree',postObj.birthDatethree);
		formData.append('birth_datefour',postObj.birthDatefour);
		formData.append('birth_datefive',postObj.birthDatefive);
		formData.append('birth_datesix',postObj.birthDatesix);
		formData.append('number_children',postObj.numberChildren);
		formData.append('male_children',postObj.maleChildren);
		formData.append('female_children',postObj.femaleChildren);
		formData.append('dead_children',postObj.deadChildren);
		formData.append('first_period_date',postObj.firstPeriodDate);
		formData.append('normal_period',postObj.normalPeriod);
		formData.append('period_lasts',postObj.periodLasts);
		formData.append('breast_feed',postObj.breastFeed);
		formData.append('last_child',postObj.lastChild);
		formData.append('birth_typetwo',postObj.birthTypetwo);
		formData.append('birth_typethree',postObj.birthTypethree);
		formData.append('birth_typefour',postObj.birthTypefour);
		formData.append('birth_typefive',postObj.birthTypefive);
		formData.append('birth_typesix',postObj.birthTypesix);
		formData.append('force_contraception',postObj.forceContraception);
		formData.append('force_contraception_note',postObj.forceContraceptionNote);
		formData.append('note_text',postObj.noteText);
		formData.append('force_save',postObj.forcefullySave);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	updateItem(patientID,stateObj,branchId,postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = '/api/edit_patient';
		const formData = new FormData();
		formData.append('id', patientID);
        formData.append('first_name',stateObj.firstName);
		formData.append('patient_type',stateObj.patientType);
		formData.append('gender',stateObj.gender);
		formData.append('phone_no',stateObj.phoneNo);
		formData.append('dob',stateObj.dob);
		formData.append('siblings',stateObj.siblings);
		formData.append('birth_order',stateObj.birthOrder);
		formData.append('secondary_phone',postObj.secondaryPhone);
		formData.append('blood_type',stateObj.bloodType);
		formData.append('city',stateObj.city);
		formData.append('district',stateObj.district);
		formData.append('birth_placetwo',postObj.birthPlacetwo);
		formData.append('birth_placethree',postObj.birthPlacethree);
		formData.append('birth_placefour',postObj.birthPlacefour);
		formData.append('birth_placefive',postObj.birthPlacefive);
		formData.append('birth_placesix',postObj.birthPlacesix);
		formData.append('responsibility_two',postObj.responsibilityTwo);
		formData.append('responsibility_three',postObj.responsibilityThree);
		formData.append('responsibility_four',postObj.responsibilityFour);
		formData.append('responsibility_five',postObj.responsibilityFive);
		formData.append('responsibility_six',postObj.responsibilitySix);
		formData.append('birth_resulttwo',postObj.birthResulttwo);
		formData.append('birth_resultthree',postObj.birthResultthree);
		formData.append('birth_resultfour',postObj.birthResultfour);
		formData.append('birth_resultfive',postObj.birthResultfive);
		formData.append('birth_resultsix',postObj.birthResultsix);
		formData.append('mother_name',postObj.motherName);
		formData.append('house_no',postObj.houseNo);
		formData.append('nearest_point',postObj.nearestPoint);
		formData.append('your_profession',postObj.yourProfession);
		formData.append('your_education',postObj.yourEducation);
		formData.append('husbands_name',postObj.husbandsName);
		formData.append('husbands_profession',postObj.husbandsProfession);
		formData.append('husband_education',postObj.husbandEducation);
		formData.append('next_kin',postObj.nextKin);
		formData.append('relationship',postObj.relationship);
		formData.append('phone_kin',postObj.phoneKin);
		formData.append('address_kin',postObj.addressKin);
		formData.append('age_married',postObj.ageMarried);
		formData.append('preg_lengthone',postObj.pregLengthone);
		formData.append('preg_lengthtwo',postObj.pregLengthtwo);
		formData.append('preg_lengththree',postObj.pregLengththree);
		formData.append('preg_lengthfour',postObj.pregLengthfour);
		formData.append('preg_lengthfive',postObj.pregLengthfive);
		formData.append('preg_lengthsix',postObj.pregLengthsix);
		formData.append('date_marriage',postObj.dateMarriage);
		formData.append('couple_related',postObj.coupleRelated);
		formData.append('birth_datetwo',postObj.birthDatetwo);
		formData.append('birth_datethree',postObj.birthDatethree);
		formData.append('birth_datefour',postObj.birthDatefour);
		formData.append('birth_datefive',postObj.birthDatefive);
		formData.append('birth_datesix',postObj.birthDatesix);
		formData.append('number_children',postObj.numberChildren);
		formData.append('male_children',postObj.maleChildren);
		formData.append('female_children',postObj.femaleChildren);
		formData.append('dead_children',postObj.deadChildren);
		formData.append('first_period_date',postObj.firstPeriodDate);
		formData.append('normal_period',postObj.normalPeriod);
		formData.append('period_lasts',postObj.periodLasts);
		formData.append('breast_feed',postObj.breastFeed);
		formData.append('last_child',postObj.lastChild);
		formData.append('birth_typetwo',postObj.birthTypetwo);
		formData.append('birth_typethree',postObj.birthTypethree);
		formData.append('birth_typefour',postObj.birthTypefour);
		formData.append('birth_typefive',postObj.birthTypefive);
		formData.append('birth_typesix',postObj.birthTypesix);
		formData.append('force_contraception',postObj.forceContraception);
		formData.append('force_contraception_note',postObj.forceContraceptionNote);
		formData.append('note_text',postObj.noteText);
		formData.append('force_save',postObj.forcefullySave);
		formData.append('branch_id',branchId);
		formData.append('diagnosis', postObj.diagnosis);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getItem(patientID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = '/api/get_patient/' + patientID;
		return instance().get(api)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	changeStatus(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = '/api/delete_patient';
		const formData = new FormData();
		formData.append('id', postObj.id);
		formData.append('status', postObj.status);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	movePatient(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = '/api/move_patient';
		const formData = new FormData();
		formData.append('patient_id', postObj.patientID);
		formData.append('branch_id', postObj.branchID);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}