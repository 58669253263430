
import { Vue, Options } from "vue-class-component";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";
import { reactive } from "vue";
import ObservationService from "../service/ObservationService";
import Confirmation from "../components/Confirmation.vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { camelCase } from "lodash";
import moment from "moment";
import ToggleButton from 'primevue/togglebutton';



interface recordTypes {
    pDate: Date;
}

@Options({
    components: {
        Confirmation,
        ToggleButton
    }
})
export default class Observations extends Vue {
    private recordList: recordTypes[] = [];
    private recordID = 0;
    private pService;
    private submitted = false;
    private toast;
    private componentName           = "";
    private diagnosisList           = [];
    private effectsList             = [];
    private vuexStore = useStore();

    private item = {
        'chiefComplaintNotes': '',
        'historyNotes': '',
        'examinationNotes': '',
        'sideEffects': '',
        'diagnosis': 'None',
        'alcohol': '',
        'smoking': false,
        'sleeping': false,
        'exercise': false,
        'coffee': false,    
    }

    private state = reactive({
        chiefComplaint: ''
    });

    private validationRules = {
        chiefComplaint: {
            required
        }
    };

    private v$ = useVuelidate(this.validationRules, this.state);

    created() {
        this.pService = new ObservationService();
        this.toast = new Toaster();
    }

    mounted() {
        this.loadList();
    }

    get visitDate() {
        return this.vuexStore.getters.getVisitDate;
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }

    saveItem(isFormValid) {
        this.submitted = true;
        if (isFormValid) {
            this.pService
                .saveObservation(this.state,this.item, this.patientID)
                .then(res => {
                    this.clearItems();
                    this.toast.handleResponse(res);
                    this.loadList();
                });
        }
    }

    clearItems() {
        this.state.chiefComplaint = "";
        this.item.smoking = false;
        this.item.examinationNotes = '';
        this.item.diagnosis = '';
        this.item.sideEffects = '';
        this.item.historyNotes = '';
        this.item.chiefComplaintNotes = '';
        this.submitted = false;
    }

    loadList() {
        this.pService.getObservation(this.patientID).then(data => {
            const res = this.camelizeKeys(data);
            this.recordList = res.records;
            this.diagnosisList = res.diagnosis;
            this.effectsList = res.sideEffects;
        });
    }

    camelizeKeys = obj => {
        if (Array.isArray(obj)) {
            return obj.map(v => this.camelizeKeys(v));
        } else if (obj !== null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.camelizeKeys(obj[key])
                }),
                {}
            );
        }
        return obj;
    };

     get sortedRecords() {
        let sortedList = {};

        this.recordList.forEach(e => {
            
            const d = moment().format("DD-MMM-YYYY");
            let date = moment(e.pDate).format("DD-MMM-YYYY");
            
            if (date == d) {
                date = "Today "+date;
            } 

           
            if (sortedList[date]) {
                sortedList[date].push(e);
            } else {
                sortedList[date] = [e];
            }
        });

        return sortedList;
    }

    get getTodaysDate()
    {
        return "Today " + moment().format("DD-MMM-YYYY");
    }

    deleteItem(id) {
        this.vuexStore.dispatch(
            ActionTypes.GET_RECEIPT_TITLE,
            "Are you sure to delete this"
        );
        this.componentName = "Confirmation";
        this.recordID = id;
    }

    updateConfirmation(b) {
        this.componentName = "";
        if (b.result) {
            this.pService.deleteObservation(this.recordID).then(res => {
                this.toast.handleResponse(res);
                this.loadList();
            });
        }
    }

    checkTodaysDate(visitDate)
    {
        return  moment(visitDate).isSame(moment(), 'day');
    }
}
