
import { Vue, Options } from "vue-class-component";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";
import { reactive } from "vue";
import FamilyService from "../service/FamilyService";
import Confirmation from "../components/Confirmation.vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { camelCase } from "lodash";
import moment from "moment";
import AutoComplete from "primevue/autocomplete";
import ToggleButton from "primevue/togglebutton";

interface IRecordList {
    relationship: string;
    pDate: string;
}


@Options({
    components: {
        Confirmation,
        AutoComplete,
        ToggleButton,
    },
})
export default class FamilyHistory extends Vue {
    private recordList: IRecordList[] = [];
    private recordID = 0;
    private familyHistoryIndex = 0;
    private submitted = false;
    private pService;
    private illnessList = [];
    private yList = [{ name: "Yes" }];
 //   private yList = [{ name: "Yes" }, { name: "No" }];
    private toast;
    private componentName = "";
    private vuexStore = useStore();

    private item = {
        optionSelected: "Yes",
        status: "History",
        description: "",
    };

    private state = reactive({
        illness: "",
    });

    private validationRules = {
        illness: {
            required,
        },
    };

    private v$ = useVuelidate(this.validationRules, this.state);

    created() {
        this.pService = new FamilyService();
        this.toast = new Toaster();
    }

    mounted() {
        this.loadList();
        this.loadRequiredList();
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }

    get visitDate() {
        return this.vuexStore.getters.getVisitDate;
    }

    saveItem(isFormValid) {
        this.submitted = true;
        if (isFormValid) {
            this.pService
                .save(this.state, this.item, this.patientID)
                .then((res) => {
                    this.clearItems();
                    this.toast.handleResponse(res);
                    this.loadList();
                });
        }
    }

    clearItems() {
        this.state.illness = "";
        this.item.optionSelected = "Yes";
        this.item.description = "";
        this.submitted = false;
    }

    loadList() {
        this.pService.get(this.patientID).then((data) => {
            const res = this.camelizeKeys(data);
            this.recordList = res.records;
        });
    }

    loadRequiredList() {
        this.pService.getIllness().then((data) => {
            const res = this.camelizeKeys(data);
            this.illnessList = res.illness;
            // this.relationshipList = res.relationship;
        });
    }

    camelizeKeys = (obj) => {
        if (Array.isArray(obj)) {
            return obj.map((v) => this.camelizeKeys(v));
        } else if (obj !== null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.camelizeKeys(obj[key]),
                }),
                {}
            );
        }
        return obj;
    };

    deleteItem(id) {
        this.vuexStore.dispatch(
            ActionTypes.GET_RECEIPT_TITLE,
            "Are you sure to delete this"
        );
        this.componentName = "Confirmation";
        this.recordID = id;
    }

    updateConfirmation(b) {
        this.componentName = "";
        if (b.result) {
            this.pService.delete(this.recordID).then((res) => {
                this.toast.handleResponse(res);
                this.loadList();
            });
        }
    }

    searchIllness(event) {
        setTimeout(() => {
            this.pService.searchItem(event.query.trim()).then((data) => {
                this.illnessList = data.records;
            });
        }, 200);
    }

    saveIllness(event) {
        const itemInfo = event.value;
        this.state.illness = itemInfo.option_name;
    }

    searchRelationship(event) {
        setTimeout(() => {
            this.pService.searchRelation(event.query.trim()).then((data) => {
                //  this.relationshipList = data.records;
            });
        }, 200);
    }

    // saveRelationship(event) {
    //   const itemInfo = event.value;
    //   this.state.relationship = itemInfo.option_name;
    // }

    get getTodaysDate() {
        return moment().format("YYYY-MM-DD");
    }

    formatDate(d) {
        return moment(d).format("YYYY-MM-DD");
    }

    formatDateTime(d) {
        return moment(d).format("DD-MM-YYYY");
    }

    checkTodaysDate(visitDate) {
        return moment(visitDate).isSame(moment(), "day");
    }

    changeStatus() {
        
        if (this.familyHistoryIndex == 0) {
            this.item.status = "History";
        } 
        else if (this.familyHistoryIndex == 1) {
            this.item.status = "Pregnancy";
        }
        else 
        {
            this.item.status = "Medical";
        }
    }

    get filterRecordList() {
        let l: IRecordList[] = [];

        if (this.familyHistoryIndex == 0) {
            l = this.recordList.filter((f) => f.relationship == "History");
        } 
        else if (this.familyHistoryIndex == 1) {
            l = this.recordList.filter((f) => f.relationship == "Pregnancy");
        }
        else
        {
            l = this.recordList.filter((f) => f.relationship == "Medical");
        }

        return l;
    }

    get sortedRecords() {
        let sortedList = {};

        this.filterRecordList.forEach((e) => {
            const d = moment().format("DD-MMM-YYYY");
            let date = moment(e.pDate).format("DD-MMM-YYYY");

            if (date == d) {
                date = "Today " + date;
            }

            if (sortedList[date]) {
                sortedList[date].push(e);
            } else {
                sortedList[date] = [e];
            }
        });

        return sortedList;
 
   }


   get getTheHistoryTypeLen() {
       return  this.recordList.filter((f) => f.relationship == "History").length;
    }

    get getTheMedicalHistoryTypeLen() {
       return  this.recordList.filter((f) => f.relationship == "Pregnancy").length;
    }
 

}
