
import { Options, Vue } from "vue-class-component";
import SaleService from "../../service/SaleService.js";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required} from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class SaleServices extends Vue {
  private lists = [];
  private dialogTitle;
  private keyword = '';
  private toast;
  private goToFirstLink = 0;
  private currentStoreID = 0;
  private saleService;
  private incomeAccountList;
  private productDialog = false;
  private submitted = false;
  private statusDialog = false;
  private checkPagination = true;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private storeList = [];
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Sale Services" },
  ];

  private selectedStore = {
    id: 0
  };

  private item = {
    id: 0,
    description: "",
    status: "Active",
  };
  

  private state = reactive({
    serviceName: "",
    charges: 0,
    incomeAccount: {},
  });

  private validationRules = {
    serviceName: {
      required,
    },
    charges: {
      required,
    },
    incomeAccount: {
      required,
    }
  };
  

  private v$ = useVuelidate(this.validationRules, this.state);

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  created() {
    this.saleService = new SaleService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.clearItem();

    this.submitted = false;
    this.dialogTitle = "Add New Service";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(isFormValid) {
    this.submitted = true;
    if (isFormValid) {
      if (this.item.id != 0) {
        this.saleService
          .updateItem(
            this.item,
            this.state
          )
          .then((res) => {
            this.loadList(this.goToFirstLink);
            //SHOW NOTIFICATION
            this.toast.handleResponse(res);
          });
      } else {
        this.saleService
          .saveItem(
            this.item,
            this.state,
            this.currentStoreID
          )
          .then((res) => {
            this.goToFirstLink = 0;
            this.loadList(this.goToFirstLink);
            //SHOW NOTIFICATION
            this.toast.handleResponse(res);
          });
      }

      this.productDialog = false;
      this.clearItem();
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Service";
    this.productDialog = true;

    this.saleService.getItem(data).then((res) => {
      if (res.length > 0) {
        this.state.serviceName         = res[0].service_name;
        this.state.charges             = res[0].charges;
        this.state.incomeAccount   = res[0].income_account;
        this.item.status               = res[0].status;
        this.item.description          = res[0].description == null ? "" : res[0].description;
        this.item.id                   = res[0].id;

         this.incomeAccountList.filter((elem) => {
          if (elem.id == res[0].income_account) {
            this.state.incomeAccount = elem;
          }
        });
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data) {
    this.item.id = data.id;
    this.state.serviceName = data.service_name;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.item.status = "Delete";
    this.saleService.changeStatus(this.item).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.saleService.getItems(this.selectedStore.id,this.keyword,page).then((data) => {
      this.lists              = data.records;
      this.totalRecords       = data.totalRecords;
      this.incomeAccountList  = data.incomeAccountList;
      this.storeList          = data.stores;
      this.limit              = data.limit;
      this.currentStoreID     = data.currentStoreID;
    });
  }

  clearItem() {

    this.item = {
      id: 0,
      description: "",
      status: "Active",
    };

    this.state.serviceName =  "";
    this.state.charges =  0;
    this.state.incomeAccount =  {};
  }

  loadSearchData() {
    this.submitted = true;
    this.goToFirstLink = 0;
    this.loadList(0);
  }
}
