
import { Options, Vue } from "vue-class-component";
import BranchService from "../../service/BranchService.js";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class Branches extends Vue {
  private lists = [];
  private chartList = [
    {
      id:0,
      account_name: '',
    }
  ];
  private dialogTitle;
  private keyword = "";
  private toast;
  private goToFirstLink = 0;
  private BranchService;
  private productDialog = false;
  private submitted = false;
  private statusDialog = false;
  private checkPagination = true;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Stores" },
  ];

  private item = {
    id: 0,
    description: "",
    licenseNo: "",
    email: "",
    contact: "",
    status: "Active",
    show1: true,
    taxName1: "",
    taxValue1: 0,
    link1: 0,
    requiredOptional1: "Required",
    show2: true,
    taxName2: "",
    taxValue2: 0,
    requiredOptional2: "Optional",
    link2: 0,
    show3: false,
    taxName3: "",
    taxValue3: 0,
    requiredOptional3: "Required",
    link3: 0,
  };

  private state = reactive({
    name: "",
    code: "",
    address: "",
  });

  private validationRules = {
    name: {
      required,
    },
    code: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(4),
    },
    address: {
      required,
    },
  };

  private v$ = useVuelidate(this.validationRules, this.state);

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  created() {
    this.BranchService = new BranchService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.clearItem();

    this.submitted = false;
    this.dialogTitle = "Add New Branch";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(isFormValid) {
    this.submitted = true;
    if (isFormValid) {
      if (this.item.id != 0) {
        this.BranchService.updateItem(
          this.item,
          this.state.name,
          this.state.code,
          this.state.address
        ).then((res) => {
          this.loadList(this.goToFirstLink);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.BranchService.saveItem(
          this.item,
          this.state.name,
          this.state.code,
          this.state.address
        ).then((res) => {
          this.goToFirstLink = 0;
          this.loadList(this.goToFirstLink);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.clearItem();
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Branch";
    this.productDialog = true;

    this.BranchService.getItem(data).then((res) => {
      if (res.length > 0) {
        this.state.name = res[0].name;
        this.state.code = res[0].code;
        this.state.address = res[0].address;
        this.item.description =
          res[0].description == null ? "" : res[0].description;
        this.item.email = res[0].email == null ? "" : res[0].email;
        this.item.licenseNo =
          res[0].license_no == null ? "" : res[0].license_no;
        this.item.contact = res[0].contact == null ? "" : res[0].contact;
        this.item.status = res[0].status == null ? "" : res[0].status;
        this.item.id = res[0].id;

        this.item.taxName1 = res[0].tax_name_1 == null ? "" : res[0].tax_name_1;
        this.item.taxName2 = res[0].tax_name_2 == null ? "" : res[0].tax_name_2;
        this.item.taxName3 = res[0].tax_name_3 == null ? "" : res[0].tax_name_3;

        this.item.taxValue1 = Number(res[0].tax_value_1);
        this.item.taxValue2 = Number(res[0].tax_value_2);
        this.item.taxValue3 = Number(res[0].tax_value_3);

        this.item.show1 = res[0].show_1 == 'true' ? true : false;
        this.item.show2 = res[0].show_2 == 'true' ? true : false;
        this.item.show3 = res[0].show_3 == 'true' ? true : false;

        this.item.requiredOptional1 = res[0].required_optional_1;
        this.item.requiredOptional2 = res[0].required_optional_2;
        this.item.requiredOptional3 = res[0].required_optional_3;

        this.item.link1 = res[0].link1;
        this.item.link2 = res[0].link2;
        this.item.link3 = res[0].link3;
        
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data) {
    this.item.id = data.id;
    this.state.name = data.name;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.item.status = "Delete";
    this.BranchService.changeStatus(this.item).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.BranchService.getItems(this.keyword, page).then((data) => {
      this.lists = data.records;
      this.totalRecords = data.totalRecords;
      this.limit = data.limit;
      this.chartList = data.chartList;

      this.item.link1 = this.chartList[0].id;
      this.item.link2 = this.chartList[0].id;
      this.item.link3 = this.chartList[0].id;
    });
  }

  clearItem() {
    this.item.id = 0;
    this.item.description = "";
    this.item.status = "Active";
    this.item.licenseNo = "";
    this.item.email = "";
    this.item.contact = "";

    this.state.name = "";
    this.state.code = "";
    this.state.address = "";
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.goToFirstLink = 0;
      this.loadList(0);
    }
  }

  setTaxesDefault(taxNumer)
  {
   
    if(taxNumer == 'first')
    {
      if(this.item.show1 == false)
      {
        this.item.taxName1 = '';
        this.item.taxValue1 = 0;
      }
    }
    else if(taxNumer == 'second')
    {
      if(this.item.show2 == false)
      {
        this.item.taxName2 = '';
        this.item.taxValue2 = 0;
      }
    }
    else if(taxNumer == 'third')
    {
      if(this.item.show3 == false)
      {
        this.item.taxName3 = '';
        this.item.taxValue3 = 0;
      }
    }
  }
}
