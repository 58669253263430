
import { Vue, Options } from "vue-class-component";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";
import { reactive } from "vue";
import ChildConsultantService from "../service/ChildConsultantService";
import Confirmation from "../components/Confirmation.vue";
import { camelCase } from "lodash";
import moment from "moment";
import AutoComplete from "primevue/autocomplete";
import ToggleButton from "primevue/togglebutton";

interface IQuestionList {
    monthName: String;
    question: String;
    type: String;
    answer: String;
    qty: Number;
    stockId: Number;
}

interface IRecordList {
    answer: String;
    branchId: Number;
    createdAt: String;
    createdBy: Number;
    id: Number;
    monthName: String;
    pDate: String
    patientId: Number;
    question: String;
    status: String;
    stockId: Number;
    type: String;
    updatedAt: String;
}

@Options({
    components: {
        Confirmation,
        AutoComplete,
        ToggleButton,
    },
})
export default class ChildMonitoring extends Vue {
    private recordList: IRecordList []  = [];
    private questionList: IQuestionList[] = [];
    private recordID = 0;
    private childMonitoringIndex = 0;
    private chosenMonth = "Month 0";
    private submitted = false;
    private pService;
    private monthList = [
    { optionName: "Risk Factor" },
    { optionName: "Month 0" },
    { optionName: "Month 2" },
    { optionName: "Month 4" },
    { optionName: "Month 6" },
    { optionName: "Month 9" },
    { optionName: "Month 12" },
    { optionName: "Month 15" },
    { optionName: "Month 18" },
    { optionName: "Year 3-5" },
    ];

    private answerList = [{ key: "Yes" }, { key: "No" }];

    private imuAnswerList = [
        { key: "Yes" },
        { key: "Vaccine is not in stock" },
        { key: "Missed visit" },
        { key: "Holiday" },
        { key: "DT" },
        { key: "No" },
    ];

    private relationshipList = [];
    private yList = [{ name: "Yes" }, { name: "No" }];
    private toast;
    private componentName = "";
    private vuexStore = useStore();

    created() {
        this.pService = new ChildConsultantService();
        this.toast = new Toaster();
    }

    mounted() {
        this.getTheQuestionList();
        this.loadList();
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }

    saveItem(q) {
        this.pService
            .saveChildMonitoring(
                q.question,
                q.answer,
                q.stockId,
                this.patientID,
                this.getChildType,
                this.chosenMonth
            )
            .then((res) => {
                this.toast.handleResponse(res);
                if(this.getChildType == 'Immunization')
                {
                  this.getTheQuestionList(); 
                }
                this.loadList();
            });
    }

    clearItems() {
        this.submitted = false;
    }

    get visitDate() {
        return this.vuexStore.getters.getVisitDate;
    }

    loadList() {
        this.pService
            .getFeedback(this.patientID)
            .then((data) => {
                const res = this.camelizeKeys(data);
                this.recordList = res.records;
            });
    }

    camelizeKeys = (obj) => {
        if (Array.isArray(obj)) {
            return obj.map((v) => this.camelizeKeys(v));
        } else if (obj !== null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.camelizeKeys(obj[key]),
                }),
                {}
            );
        }
        return obj;
    };

    deleteItem(id) {
        this.vuexStore.dispatch(
            ActionTypes.GET_RECEIPT_TITLE,
            "Are you sure to delete this"
        );
        this.componentName = "Confirmation";
        this.recordID = id;
    }

    updateConfirmation(b) {
        this.componentName = "";
        if (b.result) {
            this.pService.delete(this.recordID).then((res) => {

              if(this.getChildType == 'Immunization')
              {
                this.getTheQuestionList(); 
              }

              this.toast.handleResponse(res);

              this.loadList();
            });
        }
    }

    get getTodaysDate() {
        return  moment().format("DD-MM-YYYY");
    }

    get getChildType() {
        let t = "Eyesight";

        if (this.childMonitoringIndex == 0) {
            t = "Eyesight";
        } else if (this.childMonitoringIndex == 1) {
            t = "Hearing";
        } else if (this.childMonitoringIndex == 2) {
            t = "General Child Info";
        } else if (this.childMonitoringIndex == 3) {
            t = "Immunization";
        }

        return t;
    }

    getTheQuestionList() {

        this.questionList = [];

        this.pService.getQuestions()
            .then((data) => {
                
                if(data.records.length > 0)
                {
                    data.records.forEach((e) => {
                        const q = {
                            monthName: e.month_name,
                            question: e.question,
                            type: e.type,
                            answer: "Yes",
                                qty: 0,
                                stockId: 0,
                        };
                        this.questionList.push(q);
                    });
                }

                if(data.im_records.length > 0)
                {
                    data.im_records.forEach((e) => {
                        const q = {
                            monthName: e.option_name,
                            question: e.product_name,
                            qty: e.qty,
                            type: 'Immunization',
                            answer: "Yes",
                            stockId: e.id,
                        };
                        this.questionList.push(q);
                    });
                }   
            });
    }

    formatDate(d) {
        return moment(d).format("YYYY-MM-DD");
    }

    reFormatDate(d) {
        return moment(d).format("DD-MM-YYYY");
    }

    checkTodaysDate(visitDate) {
        return moment(visitDate).isSame(moment(), "day");
    }

   get setQuestionsList()
    {
        let qL: IQuestionList [] = [];

        if(this.childMonitoringIndex == 0)
        {
            qL = this.questionList.filter(f => f.monthName == this.chosenMonth && f.type == this.getChildType);  
        }
        else if(this.childMonitoringIndex == 1)
        {
            qL = this.questionList.filter(f => f.monthName == this.chosenMonth && f.type == this.getChildType);  
        }
        else if(this.childMonitoringIndex == 2)
        {
            qL = this.questionList.filter(f => f.monthName == this.chosenMonth && f.type == this.getChildType);  
        }
        else if(this.childMonitoringIndex == 3)
        {
            qL = this.questionList.filter(f => f.monthName == this.chosenMonth && f.type == this.getChildType);  
        }

        return qL;
    }

    get gerRecordList()
    {
        const f =  this.recordList.filter(f => f.type == this.getChildType && f.monthName == this.chosenMonth);
        return f;
    }
}
