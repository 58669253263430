import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';

export default class MedicationService {
	get(patientID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = '/api/get_medication';
		const formData = new FormData();
		formData.append('patientID', patientID);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
		.catch((e) => ExceptionHandling.HandleErrors(e))
		.finally(() => {
			store.dispatch(ActionTypes.PROGRESS_BAR, false);
		})
	}

	save(postObj,patientID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = '/api/add_medication';
		const formData = new FormData();
		formData.append('patientID',patientID);
		formData.append('drug_id',postObj.medicineID);
		formData.append('dose',postObj.dose);
		formData.append('dose_unit',postObj.unit);
		formData.append('frequency',postObj.frequency);
		formData.append('duration',postObj.duration);
		formData.append('duration_unit',postObj.days);
		formData.append('additional_instructions',postObj.instruction);
		return instance()(
		{
			method: 'post',
			url: api,
			data: formData,
		}
		).then(res => res.data)
		.catch((e) => ExceptionHandling.HandleErrors(e))
		.finally(() => {
			store.dispatch(ActionTypes.PROGRESS_BAR, false);
		})
	}
	
	delete(recordID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = '/api/del_medication';
		const formData = new FormData();
		formData.append('id',recordID);
		formData.append('status','Delete');
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}