
import { Vue, Options } from "vue-class-component";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";
import MedicationService from "../service/MedicationService";
import Confirmation from "../components/Confirmation.vue";
import { camelCase } from "lodash";
import moment from "moment";
import PosService from "../service/PosService.js";
import AutoComplete from "primevue/autocomplete";

interface recordTypes {
    pDate: Date;
}

interface IFreqType {
    name: string;
    value: number;
}

@Options({
    components: {
        Confirmation,
        AutoComplete
    }
})
export default class Medications extends Vue {
    private recordList: recordTypes[] = [];
    private recordID = 0;
    private posService;
    private pService;
    private toast;
    private componentName = "";
    private itemScanBox = "";
    private itemAvailableQty = 0;
    private vuexStore = useStore();
    private itemList = [];
    private unitLists = [{ name: "Tablet" }, { name: "Syrup" }];
    private frequencyLists: IFreqType [] = [
        { name: "One time a day",value: 1 }, 
        { name: "Two time a day",value: 2 },
        { name: "Three time a day",value: 3 },
        { name: "Four time a day",value: 4 },
        { name: "Five time a day",value: 4 },
    ];
    private dayLists = [{ name: "Day" }, { name: "Week" }, { name: "Month" }];
    private item = {
        medicineID: 0,
        unit: "Tablet",
        frequency: 1,
        duration: 1,
        days: "Day",
        dose: 1,
        instruction: ""
    };

    created() {
        this.posService = new PosService();
        this.pService = new MedicationService();
        this.toast = new Toaster();
    }

    mounted() {
        this.loadList();
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }

    get visitDate() {
        return this.vuexStore.getters.getVisitDate;
    }

    saveItem() {
        this.pService.save(this.item, this.patientID).then(res => {
            this.clearItems();
            this.toast.handleResponse(res);
            this.loadList();
        });
    }

    clearItems() {
        this.item = {
            medicineID: 0,
            unit: "Tablet",
            frequency: 1,
            duration: 1,
            days: "Day",
            dose: 1,
            instruction: ""
        };

        this.itemScanBox = "";
    }

    loadList() {
        this.pService.get(this.patientID).then(data => {
            const res = this.camelizeKeys(data);
            this.recordList = res.records;
        });
    }

    camelizeKeys = obj => {
        if (Array.isArray(obj)) {
            return obj.map(v => this.camelizeKeys(v));
        } else if (obj !== null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.camelizeKeys(obj[key])
                }),
                {}
            );
        }
        return obj;
    };

    get sortedRecords() {
        let sortedList = {};

        this.recordList.forEach(e => {
            
            const d = moment().format("DD-MMM-YYYY");
            let date = moment(e.pDate).format("DD-MMM-YYYY");
            
            if (date == d) {
                date = "Today "+date;
            } 

           
            if (sortedList[date]) {
                sortedList[date].push(e);
            } else {
                sortedList[date] = [e];
            }
        });

        return sortedList;
    }

    get getTodaysDate()
    {
        return "Today " + moment().format("DD-MMM-YYYY");
    }

    deleteItem(id) {
        this.vuexStore.dispatch(
            ActionTypes.GET_RECEIPT_TITLE,
            "Are you sure to delete this"
        );
        this.componentName = "Confirmation";
        this.recordID = id;
    }

    updateConfirmation(b) {
        this.componentName = "";
        if (b.result) {
            this.pService.delete(this.recordID).then(res => {
                this.toast.handleResponse(res);
                this.loadList();
            });
        }
    }

    searchMedicine(event) {
        setTimeout(() => {
            this.posService.searchMedications(event.query.trim()).then(data => {
                this.itemList = data.records;
            });
        }, 200);
    }

    saveMedicine(event) {
        const itemInfo = event.value;
        this.itemScanBox = itemInfo.product_name;
        this.itemAvailableQty = itemInfo.qty;
        this.item.medicineID = itemInfo.id;
    }

    formatExpiryDate(d) {
        return moment(d).format("MMM-YYYY");
    }

    checkTodaysDate(visitDate)
    {
        return  moment(visitDate).isSame(moment(), 'day');
    }

    getFrequency(n)
    {
       const d: IFreqType [] =  this.frequencyLists.filter(f => f.value == n);
       
       let t = '';

       if(d.length > 0)
       {
           t = d[0].name; 
       }

       return t;
    }

    get totalUnitQty()
    {
        return this.item.dose * this.item.frequency * this.item.duration;
    }
}
