
import { Vue, Options } from "vue-class-component";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";
import PatientDocService from "../service/PatientDocService";
import Confirmation from "../components/Confirmation.vue";
import { camelCase } from "lodash";
import moment from "moment";
import FileUploader from "../components/FileUploader.vue";


interface recordTypes {
    pDate: Date;
}

@Options({
    components: {
        Confirmation,
        FileUploader,
    }
})
export default class PatientDocs extends Vue {
    private recordList: recordTypes[] = [];
    private recordID = 0;
    private pService;
    private uploaderStatus = false;
    private submitted = false;
    private toast;
    private componentName = "";
    private pathToFile = "";
    private params = "";
    private fileName = "";
    private vuexStore = useStore();

    created() {
        this.pService = new PatientDocService();
        this.toast = new Toaster();
    }

    mounted() {
        this.loadList();
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }

    get visitDate() {
        return this.vuexStore.getters.getVisitDate;
    }

    loadList() {
        this.pService.get(this.patientID).then(data => {
            const res = this.camelizeKeys(data);
            this.recordList = res.records;
            this.pathToFile = res.pathToFile;
            console.log(this.pathToFile);
        });
    }

    camelizeKeys = obj => {
        if (Array.isArray(obj)) {
            return obj.map(v => this.camelizeKeys(v));
        } else if (obj !== null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.camelizeKeys(obj[key])
                }),
                {}
            );
        }
        return obj;
    };

     get sortedRecords() {
        let sortedList = {};

        this.recordList.forEach(e => {
            
            const d = moment().format("DD-MMM-YYYY");
            let date = moment(e.pDate).format("DD-MMM-YYYY");
            
            if (date == d) {
                date = "Today "+date;
            } 

           
            if (sortedList[date]) {
                sortedList[date].push(e);
            } else {
                sortedList[date] = [e];
            }
        });

        return sortedList;
    }

    get getTodaysDate()
    {
        return "Today " + moment().format("DD-MMM-YYYY");
    }

    deleteItem(id) {
        this.vuexStore.dispatch(
            ActionTypes.GET_RECEIPT_TITLE,
            "Are you sure to delete this"
        );
        this.componentName = "Confirmation";
        this.recordID = id;
    }

    updateConfirmation(b) {
        this.componentName = "";
        if (b.result) {
            this.pService.delete(this.recordID).then(res => {
                this.toast.handleResponse(res);
                this.loadList();
            });
        }
    } 
    
    downloadPatientDoc(id,image) {
        this.pService.downloadPatientDoc(id).then(res => {

            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', image);
            document.body.appendChild(link);
            link.click();
        });
    }

  openFileUploader()
  {
    this.uploaderStatus = true;
  }

  updateUploaderStatus(params)
  {
    this.uploaderStatus = false;
    if(params.length > 0)
    {
      this.pService.uploadFile(this.patientID, this.patientID,params[0]).then((res) => {
        this.toast.handleResponse(res);
        this.fileName = '';
        this.loadList();
      });
    }
  }
  
    checkTodaysDate(visitDate)
    {
        return  moment(visitDate).isSame(moment(), 'day');
    }

    //  openPatientDoc(id,image) {
    //     this.pService.downloadPatientDoc(id).then(res => {

    //         const url = window.URL.createObjectURL(new Blob([res]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('href', image);
    //         console.log(link);
    //        // document.body.appendChild(link);
    //         link.click();
    //     });
    // }
  
}
