import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';

export default class PatientConsultationService {
	getAssessment(patientID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = '/api/get_assessment';
		const formData = new FormData();
		formData.append('patientID', patientID);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	saveAssessment(stateObj, postObj, patientID,assessmentIndicators) {
		
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = '/api/add_assessment';
		const formData = new FormData();
		formData.append('patientID',patientID);
		formData.append('weight',stateObj.weight);
		formData.append('height',stateObj.height);
		formData.append('o2',postObj.o2);
		formData.append('respiration',postObj.respiration);
		formData.append('temperature',postObj.temperature);
		formData.append('pressure',postObj.pressure);
		formData.append('pulse',postObj.pulse);
		formData.append('systolic',postObj.systolic);
		formData.append('diastolic',postObj.diastolic);
		formData.append('head_circumference',postObj.headCircumference);
		formData.append('waist_circumference',postObj.waistCircumference);
		formData.append('babies_sound',postObj.babiesSound);
		formData.append('baby_movement',postObj.babyMovement);
		formData.append('age_pregnancy',postObj.agePregnancy);
		formData.append('swelling',postObj.swelling);
		formData.append('belly_size',postObj.bellySize);
		formData.append('ferfolic_medicine',postObj.ferfolicMedicine);
		formData.append('ferfolic_tabs',postObj.ferfolicTabs);
		formData.append('extra_space',postObj.extraSpace);
		formData.append('checking_breast',postObj.checkingBreast);
		formData.append('checking_belly',postObj.checkingBelly);
		formData.append('checking_womb_area',postObj.checkingWombArea);
		formData.append('birth_reason',postObj.birthReason);
		formData.append('family_planning',postObj.familyPlanning);
		formData.append('control_pregnancy',postObj.controlPregnancy);
		formData.append('miscarriage',postObj.miscarriage);
		formData.append('plavic',postObj.plavic);
		formData.append('note',postObj.note);


		formData.append('bmi_kg', assessmentIndicators.bmiValue);
		formData.append('bmi_status', assessmentIndicators.bmiComment);
		formData.append('ox_ind', assessmentIndicators.o2);
		formData.append('resp_ind', assessmentIndicators.respiration);
		formData.append('temp_ind', assessmentIndicators.temperature);
		formData.append('pre_ind', assessmentIndicators.pressure);
		formData.append('pulse_ind', assessmentIndicators.pulse);
		formData.append('syst_ind', assessmentIndicators.systolic);
		formData.append('dias_ind', assessmentIndicators.diastolic);
		formData.append('bmi_ind', assessmentIndicators.bmiStatus);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	deleteAssessment(recordID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = '/api/del_assessment';
		const formData = new FormData();
		formData.append('id', recordID);
		formData.append('status', 'Delete');
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}