
import { Options, mixins } from "vue-class-component";
import HomepageService from "../../service/HomepageService";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import { camelCase } from "lodash";
import UtilityOptions from "../../mixins/UtilityOptions";


interface IVisits {
    createdAt: string;
    total: number;
}

@Options({
    components: {},
    title: "Dashboard",
})
export default class Homepage extends mixins(UtilityOptions) {
    private stores = [];
    private branchName = "";
    private branchID = "";

    private dashInfo = {
        visitToday: 0,
        totalPatients: 0,
        medicationToday: 0,
        labToday: 0,
        assesToday: 0,
        consultationToday: 0,
        immYesToday: 0,
        immStockToday: 0,
        immMissedToday: 0,
        immHolidayToday: 0,
        immOthersToday: 0,
        immNoToday: 0,
        immYesMonth: 0,
        immStockMonth: 0,
        immMissedMonth: 0,
        immHolidayMonth: 0,
        immOthersMonth: 0,
        immNoMonth: 0,
        vaccinationTodayYes: 0,
        vaccinationTodayNo: 0,
        vaccinationMonthYes: 0,
        vaccinationMonthNo: 0,
    };

    private recentlyItems = [];
    private visitMonth: IVisits[] = [];
    private visitLastMonth: IVisits[] = [];
    private homepageTxn;
    private toast;

    private multiAxisOptions = {
        responsive: true,
        tooltips: {
            mode: "index",
            titleFontSize: 30,
            bodyFontSize: 22,
            intersect: true,
        },
    };

    private storeActivityChartOptions = {
        responsive: true,
        tooltips: {
            mode: "index",
            titleFontSize: 30,
            bodyFontSize: 22,
            intersect: true,
        },
        scales: {
            // yAxes: [
            //   {
            //     type: "linear",
            //     display: false,
            //     position: "left",
            //     id: "y-axis-1",
            //   },
            // ],
        },
    };

    //DEFAULT METHOD OF TYPE SCRIPT
    //CALLING WHENEVER COMPONENT LOADS
    created() {
        this.homepageTxn = new HomepageService();
        this.toast = new Toaster();
    }

    mounted() {
        this.getHomepageDetails();
    }

    camelizeKeys = (obj) => {
        if (Array.isArray(obj)) {
            return obj.map((v) => this.camelizeKeys(v));
        } else if (obj !== null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.camelizeKeys(obj[key]),
                }),
                {}
            );
        }
        return obj;
    };

    getHomepageDetails() {
        this.homepageTxn.getHompageDetails(this.branchID).then((res) => {
            const d = this.camelizeKeys(res);
         
            this.dashInfo.visitToday = d.visitToday;
            this.dashInfo.totalPatients = d.totalPatients;
            this.dashInfo.medicationToday = d.medicationToday;
            this.dashInfo.labToday = d.labToday;
            this.dashInfo.assesToday = d.assesToday;
            this.dashInfo.consultationToday = d.consultationToday;
            this.dashInfo.immYesToday = d.immYesToday;
            this.dashInfo.immStockToday = d.immStockToday;
            this.dashInfo.immMissedToday = d.immMissedToday;
            this.dashInfo.immHolidayToday = d.immHolidayToday;
            this.dashInfo.immOthersToday = d.immOthersToday;
            this.dashInfo.immNoToday = d.immNoToday;
            this.dashInfo.immYesMonth = d.immYesMonth;
            this.dashInfo.immStockMonth = d.immStockMonth;
            this.dashInfo.immMissedMonth = d.immMissedMonth;
            this.dashInfo.immHolidayMonth = d.immHolidayMonth;
            this.dashInfo.immOthersMonth = d.immOthersMonth;
            this.dashInfo.immNoMonth = d.immNoMonth;
            this.dashInfo.vaccinationTodayYes = d.vaccinationTodayYes;
            this.dashInfo.vaccinationTodayNo = d.vaccinationTodayNo;
            this.dashInfo.vaccinationMonthYes = d.vaccinationMonthYes;
            this.dashInfo.vaccinationMonthNo = d.vaccinationMonthNo;
            this.recentlyItems = d.recentlyAdded;
            this.branchName = d.storeInfo.name;
            //this.branchID                     = d.storeInfo.id;
            this.visitMonth = d.monthVisits;
            this.visitLastMonth = d.lastVisits;

           console.log(d.thisMonthVisits);

            this.stores = d.stores;
        });
    }

    get getVisitsMonthRecord() {
        let day: string[] = [];
        let visits: number[] = [];

        this.visitMonth.map((m) => {

            const s = moment(m.createdAt).format("DD");
            const v = m.total;

            day.push(s);
            visits.push(v);
        });

        return [day, visits];
    }
    
    get getVisitsLastRecord() {
        let day: string[] = [];
        let visits: number[] = [];

        this.visitLastMonth.map((m) => {

            const s = moment(m.createdAt).format("DD");
            const v = m.total;

            day.push(s);
            visits.push(v);
        });

        return [day, visits];
    }

    get multiAxisData() {
        return {
            labels: this.getVisitsMonthRecord[0],
            datasets: [
                {
                    type: "bar",
                    label: "ئەم مانگە (#)",
                    backgroundColor: "#20c997",
                    data: this.getVisitsMonthRecord[1],
                }
            ],
        };
    } 
    
    get multiAxisData2() {
        return {
            labels: this.getVisitsLastRecord[0],
            datasets: [
                {
                    type: "bar",
                    label: "مانگی ڕابردوو (#)",
                    backgroundColor: "#1a5692",
                    data: this.getVisitsLastRecord[1],
                }
            ],
        };
    }
}
