
import {  Options,mixins } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import PaymentService from "../service/PaymentService";
import UtilityOptions from "../mixins/UtilityOptions";


interface IPaymentMethod {
  bankId: number;
  branchId: number;
  cardCharges: number;
  cardName: string;
  chargeCustomer: string;
  id: string;
}

interface PaymentListType {
  paymentType: string;
  accountNo: string;
  terminalId: string;
  authCode: string;
  transId: string;
  transStatus: string;
  transType: string;
  transDate: string;
  transTime: string;
  transAmount: number;
  transTotalAmount: number;
  transRef: string;
  entryMode: string;
  hostResponse: string;
  giftCardRef: string;
  cardBalance: string;
  tendered: number;
  change: number;
  roundOff: number;
   bankID: number;
}

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      this.paymentDialog = obj.dialogStatus;
      this.closeConfirmation = obj.closeConfirmation;
      this.amountLeft = Number(this.totalBill);
      this.itemSource = obj.itemSource;
      this.restriction = obj.restriction;
      this.customerID = obj.customerID;
      this.customerName = obj.customerName;
      this.paymentAction.needlePoints = obj.needlePoints;
      this.dialogTilte = obj.dialogTilte + " for Customer " + this.customerName;
    },
  },
  emits: ["closePaymentScreenEvent", "getProceededPaymentsEvent"],
})

export default class PaymentScreen extends mixins(UtilityOptions) {
  
  private customerID;
  private methodList: IPaymentMethod [] = [];
  private customerName;
  private accountNo = "";
  private cardType = {
    bankId: 0,
    branchId: 0,
    cardCharges: 0,
    cardName: '',
    chargeCustomer: '',
    id:0
  }
  private paymentService;
  private paymentDialog = false;
  private paymentConfirmDialog = false;
  private closeConfirmation = false;
  private paymentCancelDialog = false;
  private itemSource = "";
  private transStatus = "000";
  private toast;
  private restriction = "";
  private screenNumber = "";
  private paymentMethodType = "Cash";
  private amountLeft = 0;
  private roundedAmt = 0;
  private tipAmountTerminal = 0;
  private paymentAction = {
    tendered: 0,
    needlePoints: 0,
  };

  private paymentList: PaymentListType[] = [];

  created() {
    this.paymentService = new PaymentService();
    this.toast = new Toaster();
  }

  mounted()
  {
      this.loadPaymentMethod();
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  get totalBill() {
    return this.store.getters.getTotalBill;
  }

  closePaymentScreen() {
    this.paymentList = [];
    this.$emit("closePaymentScreenEvent");
    this.paymentCancelDialog = false;
  }

  cancelPaymentConfirm() {
    this.paymentDialog = true;
    this.paymentCancelDialog = false;
  }

  confirmPaymentCancel() {
    if (this.totalPaymentsReceived > 0) {
      this.paymentCancelDialog = true;
    } else {
      this.paymentList = [];
      this.$emit("closePaymentScreenEvent");
      this.paymentDialog = false;
    }
  }

  amountNumpad(num) {
    num = String(num);

    if (num == "del") {
      this.paymentAction.tendered = 0;
      this.screenNumber = "";
    } else {
      if (this.paymentRounding > 0 || this.paymentMethodType == "Tip") {
        this.screenNumber = this.screenNumber + num;
        this.paymentAction.tendered = Number(this.screenNumber);
      } else {
        this.toast.showWarning("Invalid Amount must be greater then zero");
      }
    }
  }

  exactAmount() {
    this.paymentAction.tendered = this.paymentRounding;
  }

  fixLength(value) {
    const num = Number(value);
    value = num.toFixed(2);
    return value;
  }

  fixLengthNumber(value) {
    const num = Number(value);
    value = num.toFixed(2);
    value = Number(value);
    return value;
  }

  get totalPaymentsReceived() {
    let total = 0;

    this.paymentList.forEach((e) => {
      if (e.paymentType != "Tip") {
        total = total + e.transTotalAmount;
      }
    });

    return Number(total);
  }

  addCashAmount() {
    const tendered = Number(this.paymentAction.tendered);
    if (tendered == 0) {
      this.toast.showError("Please enter amount greater then zero");
    } else {
      if (!this.checkCashPayment) {
        const receivableAmount = this.fixLengthNumber(
          tendered - this.changeAmount
        );
        this.paymentList.push({
          paymentType: "Cash",
          accountNo: "",
          transTotalAmount: receivableAmount,
          terminalId: "Manual",
          authCode: "",
          hostResponse: "",
          transId: "",
          transStatus: this.transStatus,
          transType: this.itemSource,
          transDate: "",
          transTime: "",
          transAmount: receivableAmount,
          transRef: "",
          entryMode: "",
          giftCardRef: "",
          cardBalance: "",
          tendered: this.fixLengthNumber(tendered),
          change: this.fixLengthNumber(this.changeAmount),
          roundOff: this.fixLengthNumber(this.roundedAmt),
          bankID: 0,
        });
      } else {
        this.toast.showError("Cash type is already added");
      }
    }
  }

  addManualAmount() {
    if (this.accountNo.length != 4 || this.cardType == null) {
      this.toast.showError("Please choose Card Type and Card No must be 4 digits");
    } else {
      const tendered = Number(this.paymentAction.tendered);
      if (tendered == 0) {
        this.toast.showError("Please enter amount greater then zero");
      } else {
        const receivableAmount = this.fixLengthNumber(
          tendered - this.changeAmount
        );

        this.paymentList.push({
          paymentType: this.cardType.cardName,
          accountNo: this.accountNo,
          transTotalAmount: receivableAmount,
          terminalId: "Manual",
          authCode: "",
          hostResponse: "",
          transId: "",
          transStatus: this.transStatus,
          transType: this.itemSource,
          transDate: "",
          transTime: "",
          transAmount: receivableAmount,
          transRef: "",
          entryMode: "",
          giftCardRef: "",
          cardBalance: "",
          bankID: this.cardType.bankId,
          tendered: this.fixLengthNumber(tendered),
          change: this.fixLengthNumber(this.changeAmount),
          roundOff: 0,
        });
        this.toast.showSuccess(this.cardType.cardName + " Payment added successfully");
        this.accountNo = "";
      }
    }
  }

  get changeAmount() {
    let change = 0;
    const amountLeft = this.paymentRounding;
    const tendered = Number(this.paymentAction.tendered);
    const balance = tendered - amountLeft;

    if (balance > 0) {
      change = balance;
    }

    return change;
  }

  deletePayment(index, obj) {
    this.paymentList.splice(index, 1);
    this.toast.showSuccess(
      "Amount of $" +
        this.fixLength(obj.transTotalAmount) +
        " removed successfully"
    );
  }


  clearAccountNo() {
    this.accountNo = "";
  }

  get checkCashPayment() {
    let status = false;
    this.paymentList.forEach((e) => {
      if (e.paymentType == "Cash") {
        status = true;
      }
    });

    return status;
  }


  get paymentRounding() {
    let amountLeftTemp = 0;
    let amountPaidTemp = 0;

    //REDUCING THE AMOUNT PAID
    this.paymentList.forEach((e) => {
      if (e.paymentType != "Tip") {
        amountPaidTemp = amountPaidTemp + e.transTotalAmount;
      }
    });

    if (this.paymentMethodType == "Cash") {
      const amountLeft = this.amountLeft - amountPaidTemp;
      const roundNum = Math.round(amountLeft / 0.05) * 0.05;
      amountLeftTemp = Number(roundNum);

      if (this.roundedAmt == 0) {
        this.roundedAmt = roundNum - amountLeft;
      }
    } else if (this.paymentMethodType != "Tip") {
      amountLeftTemp = this.amountLeft - amountPaidTemp;
      this.roundedAmt = 0;
    } else {
      //nothing
    }

    this.paymentAction.tendered = 0;
    this.screenNumber = "";

    return amountLeftTemp;
  }

  confirmPayments() {
    //restriction == 'Yes'
    if (this.paymentRounding > 0.2 && this.restriction == "No") {
      this.paymentConfirmDialog = true;
    } else {
      this.emitPayments();
    }
  }

  emitPayments() {
    this.$emit("getProceededPaymentsEvent", this.paymentList);
    this.paymentDialog = false;
    this.paymentConfirmDialog = false;
    this.paymentList = [];
    this.clearPaymentScreen();
  }

  clearPaymentScreen() {
    this.amountLeft = 0;
    this.paymentAction.tendered = 0;
    this.paymentAction.needlePoints = 0;
  }

  loadPaymentMethod()
  {
    this.paymentService.paymentMethods().then((res) => {
      this.methodList = this.camelizeKeys(res.option);
    });
  }
}
