
import { Options, mixins } from "vue-class-component";
import { ref } from "vue";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";

interface IBranch {
    name: string;
}

interface IReport {
    firstName: string;
    patientNo: string;
    patientType: string;
    gender: string;
    dob: string;
    phoneNo: string;
    branchDetails: IBranch;
}

@Options({
    components: {},
})
export default class UserReport extends mixins(UtilityOptions) {
    private dt = ref();
    private lists: IReport[] = [];
    private reportService;
    private resultTitle = "";
    private productDialog = false;
    private loading = false;
    private home = { icon: "pi pi-home", to: "/" };
    private items = [
        { label: "Reports", to: "reports" },
        { label: "User Report" },
    ];

    private searchFilters = {
        id: "",
        storeID: 0,
    };
    private dialogTitle;
    private submitted = false;
    private filterBranch = [];

    //CALLING WHENEVER COMPONENT LOADS
    created() {
        this.reportService = new StoreReports();
    }

    //CALLNING AFTER CONSTRUCTOR GET CALLED
    mounted() {
        this.storeList();
        this.loadList();
    }

    //OPEN DIALOG TO ADD NEW ITEM
    openDialog() {
        this.submitted = false;
        this.dialogTitle = "Filter Report";
        this.productDialog = true;
    }

    storeList() {
        this.reportService.getFilterList().then((res) => {
            this.filterBranch = res.stores;
        });
    }

    // USED TO GET SEARCHED ASSOCIATE
    loadList() {
        this.loading = true;
        this.reportService.userReport(this.searchFilters).then((res) => {
            const data = this.camelizeKeys(res);
            this.resultTitle = data.resultTitle;
            this.lists = data.record;
            this.loading = false;
        });
        this.productDialog = false;
    }

    get rList() {
        const l: IReport[] = [];

        this.lists.forEach((e) => {
            e.dob = this.formatDate(e.dob);
            l.push(e);
        });

        return l;
    }
}
