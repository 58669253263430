
import { Vue, Options } from "vue-class-component";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";
import { reactive } from "vue";
import Confirmation from "../components/Confirmation.vue";
import { camelCase } from "lodash";
import moment from "moment";
import PatientGeneralService from "../service/PatientGeneralService.js";

interface VisitTypes {
    visitDate: string;
}

interface GeneralTypes {
    pDate: string;
}

@Options({
    components: {
        Confirmation,
    },
})
export default class GeneralInfo extends Vue {
    private recordList: VisitTypes[] = [];
    private assessments: GeneralTypes[] = [];
    private labOrders: GeneralTypes[] = [];
    private medications: GeneralTypes[] = [];
    private patientDocs: GeneralTypes[] = [];
    private generalService;
    private toast;
    private selectedVisitDate = "";
    private vuexStore = useStore();

    created() {
        this.generalService = new PatientGeneralService();
        this.toast = new Toaster();
    }

    mounted() {
        this.loadList();
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }

    loadList() {
        this.generalService.getVisits(this.patientID).then((data) => {
            const res = this.camelizeKeys(data);
            this.recordList = res.records;
            this.selectedVisitDate = this.recordList[0].visitDate;
            this.patientHistory(this.recordList[0].visitDate);
        });
    }

    patientHistory(visitDate) {
        const dt = this.formatDateHistory(visitDate);
        this.generalService
            .getPatientHistory(this.patientID, dt)
            .then((data) => {
                const res = this.camelizeKeys(data);
                this.assessments = res.assessments;
                this.labOrders = res.labOrders;
                this.patientDocs = res.patientDocs;
                this.medications = res.medications;
            });
    }

    camelizeKeys = (obj) => {
        if (Array.isArray(obj)) {
            return obj.map((v) => this.camelizeKeys(v));
        } else if (obj !== null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.camelizeKeys(obj[key]),
                }),
                {}
            );
        }
        return obj;
    };

    formatDateTime(d) {
        return moment(d).format("DD-MMM-YYYY HH:mm A");
    }

    formatDateHistory(d) {
        return moment(d).format("YYYY-MM-DD");
    }

    get sortedVitalRecords() {
        let sortedList = {};
        this.assessments.forEach((e) => {
            const d = moment().format("DD-MMM-YYYY");
            let date = moment(e.pDate).format("DD-MMM-YYYY");

            if (date == d) {
                date = "Today " + date;
            }

            if (sortedList[date]) {
                sortedList[date].push(e);
            } else {
                sortedList[date] = [e];
            }
        });

        return sortedList;
    }

    get sortedMedicationRecords() {
        let sortedList = {};

        this.medications.forEach((e) => {
            const d = moment().format("DD-MMM-YYYY");
            let date = moment(e.pDate).format("DD-MMM-YYYY");

            if (date == d) {
                date = "Today " + date;
            }

            if (sortedList[date]) {
                sortedList[date].push(e);
            } else {
                sortedList[date] = [e];
            }
        });

        return sortedList;
    }

    get sortedLabRecords() {
        let sortedList = {};

        this.labOrders.forEach((e) => {
            const d = moment().format("DD-MMM-YYYY");
            let date = moment(e.pDate).format("DD-MMM-YYYY");

            if (date == d) {
                date = "Today " + date;
            }

            if (sortedList[date]) {
                sortedList[date].push(e);
            } else {
                sortedList[date] = [e];
            }
        });

        return sortedList;
    }

    get sortedPatientRecords() {
        let sortedList = {};

        this.patientDocs.forEach((e) => {
            const d = moment().format("DD-MMM-YYYY");
            let date = moment(e.pDate).format("DD-MMM-YYYY");

            if (date == d) {
                date = "Today " + date;
            }

            if (sortedList[date]) {
                sortedList[date].push(e);
            } else {
                sortedList[date] = [e];
            }
        });

        return sortedList;
    }

    get getTodaysDate() {
        return "Today " + moment().format("DD-MMM-YYYY");
    }

    downloadPatientDoc(id, image) {
        this.generalService.downloadPatientDoc(id).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", image);
            document.body.appendChild(link);
            link.click();
        });
    }

    checkTodaysDate(visitDate)
    {
        let t = false;

    //    let isCurrentDate =  moment(visitDate).isSame(moment(), 'day');
       
    //     if(isCurrentDate == false)
    //     {
    //         t = true;
    //     }

        return t;
    }
}
