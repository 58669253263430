
import { Options, mixins } from "vue-class-component";
import { ref } from "vue";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";

interface IBranch {
    name: string;
}

interface IPatient {
    firstName: string;
    patientNo: string;
    patientType: string;
    gender: string;
    dob: string;
    phoneNo: string;
}

interface IReport {
    branchId: number;
    createdAt: string;
    createdBy: number;
    doctorName: string;
    id: number;
    notes: string;
    patientDetails: IPatient;
    branchDetails: IBranch;
    patientId: number;
    status: string;
    updatedAt: string;
    visitDate: string;
    visitFee: number;
}

@Options({
    components: { },
})
export default class VisitReport extends mixins(UtilityOptions) {
    private dt = ref();
    private lists: IReport[] = [];
    private reportService;
    private resultTitle = "";
    private productDialog = false;
    private loading = false;
    private home = { icon: "pi pi-home", to: "/" };
    private items = [
        { label: "Reports", to: "reports" },
        { label: "Visit Report" },
    ];

    private searchFilters = {
        id: "",
        date1: "",
        date2: "",
        filterType: "None",
        storeID: 0,
        days: 0,
        type: "Maternity",
        visitType: "All",
    };

    private patientTypeList = [
        { name: "Maternity" },
        { name: "Child Monitoring" },
        { name: "Vaccination & Others" },
    ];
    
    private visitTypeList = [
        { name: "All" },
        { name: "Confirmed" },
        { name: "Not Confirm" },
    ];

    private filterDates = [];
    private dialogTitle;
    private submitted = false;
    private filterBranch = [];

    //CALLING WHENEVER COMPONENT LOADS
    created() {
        this.reportService = new StoreReports();
    }

    //CALLNING AFTER CONSTRUCTOR GET CALLED
    mounted() {
        this.storeList();
        this.loadList();
    }

    //OPEN DIALOG TO ADD NEW ITEM
    openDialog() {
        this.submitted = false;
        this.dialogTitle = "Filter Report";
        this.productDialog = true;
    }

    storeList() {
        this.reportService.getFilterList().then((res) => {
            this.filterBranch = res.stores;
            this.filterDates = res.datesList;
        });
    }

    // USED TO GET SEARCHED ASSOCIATE
    loadList() {
        this.loading = true;
        this.reportService.visitReport(this.searchFilters).then((res) => {
            const data = this.camelizeKeys(res);
            this.resultTitle = data.resultTitle;
            this.lists = data.record;
            this.loading = false;
        });
        this.productDialog = false;
    }

    get rList() {
        const l: IReport[] = [];

        this.lists.forEach((e) => {
            e.visitDate = this.formatDateTime(e.visitDate);
            e.patientDetails.dob = this.formatDate(e.patientDetails.dob);
            l.push(e);
        });

        return l;
    }

    resetDays()
    {
        if(this.searchFilters.visitType == 'All')
        {
            this.searchFilters.days = 0;
        }
    }
}
