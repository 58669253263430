
import { Vue, Options } from "vue-class-component";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";
import { reactive } from "vue";
import RiskService from "../service/RiskService";
import Confirmation from "../components/Confirmation.vue";
import { camelCase } from "lodash";
import moment from "moment";
import AutoComplete from "primevue/autocomplete";
import ToggleButton from "primevue/togglebutton";


interface IQuestionList {
    description: String;
    optionName: String;
    optionType: String;
}

interface recordTypes {
    pDate: Date;
    type: String;
}

@Options({
  components: {
    Confirmation,
    AutoComplete,
    ToggleButton,
  },
})
export default class RiskFactor extends Vue {
  private recordList: recordTypes[] = [];
  private questionList: IQuestionList [] = [];
  private selectedList: IQuestionList [] = [];
  private recordID = 0;
  private riskFactorIndex = 0;
  private submitted = false;
  private pService;
  private toast;
  private componentName = "";
  private vuexStore = useStore();


  created() {
    this.pService = new RiskService();
    this.toast = new Toaster();
  }

  mounted() {
    this.getTheQuestionList();
  }

  get patientID() {
    return this.vuexStore.getters.getReceiptID;
  }

  saveItem() {
    this.pService.saveFactor(this.selectedList,this.patientID).then((res) => {
       this.toast.handleResponse(res);
       this.clearItems();
       this.loadList();
    });
  }

  clearItems() {
    this.submitted = false;
    this.selectedList = [];
  }

  get visitDate() {
        return this.vuexStore.getters.getVisitDate;
    }

  loadList() {
    this.pService.gerResultFactor(this.patientID).then((data) => {
      const res = this.camelizeKeys(data);
      this.recordList = res.records;
    });
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  deleteItem(d) {
    this.vuexStore.dispatch(ActionTypes.GET_RECEIPT_TITLE, "Are you sure to delete this");
    this.componentName = "Confirmation";
    this.recordID = d;
  }

  updateConfirmation(b) {
    this.componentName = "";
    if (b.result) {
      this.pService.delete(this.patientID).then((res) => {
        this.toast.handleResponse(res);
        this.loadList();
      });
    }
  }

  get getTodaysDate()
  {
      return "Today " + moment().format("DD-MMM-YYYY");
  }

  get getType()
  {
    let t = 'Previous Pregnancy';

    if(this.riskFactorIndex == 0)
    {
      t = 'Previous Pregnancy';
    }
    else if(this.riskFactorIndex == 1)
    {
      t = 'Previous Child Birth';
    }
    else if(this.riskFactorIndex == 2)
    {
      t = 'Current Pregnancy';
    }
    else if(this.riskFactorIndex == 3)
    {
      t = 'Previous Operation';
    }

    return t;
  }


  getTheQuestionList()
  {
    this.questionList = [];

    this.pService.getRiskQuestions().then((data) => {

      const d = this.camelizeKeys(data);
      this.questionList = d.records
    });

    this.loadList();
  }

  formatDate(d) {
    return moment(d).format("YYYY-MM-DD");
  } 
  
  formatAmount(d) {
    return (d).toFixed(2);
  }
  
  reFormatDate(d) {
    return moment(d).format("DD-MM-YYYY");
  }



  checkHeadCircumference(q)
  {
    let t = true;

    if(q == 'Waist Circumference')
    {
      t = false;
    }
    else if(q == 'Head Circumference')
    {
      t = false;
    }

    return t;
  }

  checkTodaysDate(visitDate)
  {
    return  moment(visitDate).isSame(moment(), 'day');
  }

  get filterQuestionList()
  {
    return this.questionList.filter(e => e.optionType == this.getType);
  }

  get getTotalRiskFactor()
  {
    let t = 0;

    this.selectedList.forEach(e => {
      t = t + Number(e.description);
    } );

    return t;
  }

   get sortedRecords() {
        let sortedList = {};

        this.recordList.forEach((e) => {

          if(e.type == this.getType)
          {
            const d = moment().format("DD-MMM-YYYY");

            let date = moment(e.pDate).format("DD-MMM-YYYY");

            if (date == d) {
              date = "Today " + date;
            }

            if (sortedList[date])
            {
              sortedList[date].push(e);
            } 
            else
            {
              sortedList[date] = [e];
            }
          }
        });

        return sortedList;
    }

  calculateTotalRiskFactor(i)
  {
    let c = 0;

    i.forEach(e => {
      c = c + Number(e.riskValue);
    });
    
    return c;
  }  

  get checkAlreadySaved()
  {
    let d = false;
    
    this.recordList.filter(e => {

      let pDate  = moment(e.pDate).format("YYYY-MM-DD");
      let pToday = moment().format("YYYY-MM-DD");

      if(pDate == pToday && this.getType == e.type)
      {
        d = true;
        return d;
      }
    });

    return d;
  }
}
