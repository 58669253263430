
    import { Options, Vue } from "vue-class-component";
    import { useStore } from "../store";
    import { reactive } from "vue";
    import Toaster from "../helpers/Toaster";
    import PatientService from "../service/PatientService.js";
    import { required } from "@vuelidate/validators";
    import useVuelidate from "@vuelidate/core";
    import moment from "moment";
    import VoucherService from "../service/VoucherService.js";
    
    @Options({
        inheritAttrs: false,
        emits: ["updatePatientStatus"],
    })
    export default class PatientDialog extends Vue {
        private toast;
        private submitted = false;
        private branchId = "";
        private productDialog = true;
        private duplicateDialog = false;
        private patientService;
        private vuexStore = useStore();
        private storeList = [];
        private voucherService;
        private genderTypes = [
            { name: "Male" }, 
            { name: "Female" },
];

    private bloodList = [
        { name: "Not Known" },
        { name: "A+" },
        { name: "A-" },
        { name: "B+" },
        { name: "B-" },
        { name: "O+" },
        { name: "O-" },
        { name: "AB+" },
        { name: "AB-" },
    ]; 
    
    private patientTypeList = [
        { name: "Maternity" },
        { name: "Child Monitoring" },
        { name: "Vaccination & Others" }
    ];
private optionList = [
        {
            name: "خزم نین",
        },
        {
            name: "خاڵوزا، پورزا، ئاموزا",
        },
        {
            name: "خزمایەتی تر",
        },


    ];

    private breastfeedList = [
        {
            name: "Yes",
        },
        {
            name: "No",
        },
];
private normalperiodList = [
        {
            name: "Yes",
        },
        {
            name: "No",
        },
];
private pregLengthoneList = [
        {
            name: "1 month",
        },
        {
            name: "2 month",
        },
        {
            name: "3 month",
        }, 
        {
            name: "4 month",
        },
        {
            name: "5 month",
        },
        {
            name: "6 month",
        }, 
        {
            name: "7 month",
        }, 
        {
            name: "8 month",
        },
        {
            name: "9 month",
        },
    ];



    private pregLengthtwoList = [
    {
            name: "1 month",
        },
        {
            name: "2 month",
        },
        {
            name: "3 month",
        }, 
        {
            name: "4 month",
        },
        {
            name: "5 month",
        },
        {
            name: "6 month",
        }, 
        {
            name: "7 month",
        }, 
        {
            name: "8 month",
        },
        {
            name: "9 month",
        },
    ];

    private pregLengththreeList = [
    {
            name: "1 month",
        },
        {
            name: "2 month",
        },
        {
            name: "3 month",
        }, 
        {
            name: "4 month",
        },
        {
            name: "5 month",
        },
        {
            name: "6 month",
        }, 
        {
            name: "7 month",
        }, 
        {
            name: "8 month",
        },
        {
            name: "9 month",
        },
    ];

    private pregLengthfourList = [
    {
            name: "1 month",
        },
        {
            name: "2 month",
        },
        {
            name: "3 month",
        }, 
        {
            name: "4 month",
        },
        {
            name: "5 month",
        },
        {
            name: "6 month",
        }, 
        {
            name: "7 month",
        }, 
        {
            name: "8 month",
        },
        {
            name: "9 month",
        },
    ];
    private pregLengthfiveList = [
    {
            name: "1 month",
        },
        {
            name: "2 month",
        },
        {
            name: "3 month",
        }, 
        {
            name: "4 month",
        },
        {
            name: "5 month",
        },
        {
            name: "6 month",
        }, 
        {
            name: "7 month",
        }, 
        {
            name: "8 month",
        },
        {
            name: "9 month",
        },
    ];
    private pregLengthsixList = [
    {
            name: "1 month",
        },
        {
            name: "2 month",
        },
        {
            name: "3 month",
        }, 
        {
            name: "4 month",
        },
        {
            name: "5 month",
        },
        {
            name: "6 month",
        }, 
        {
            name: "7 month",
        }, 
        {
            name: "8 month",
        },
        {
            name: "9 month",
        },
    ];
    private birthtypeList = [

         {
            name: "نەشتەرگەری",
        }, 
        {
            name: "سروشتی",
        },
    ];
    private birthtypetwoList = [

         {
            name: "نەشتەرگەری",
        }, 
        {
            name: "سروشتی",
        },
    ];
    private birthtypethreeList = [

         {
            name: "نەشتەرگەری",
        }, 
        {
            name: "سروشتی",
        },
    ];
    private birthtypefourList = [

         {
            name: "نەشتەرگەری",
        }, 
        {
            name: "سروشتی",
        },
    ];
    private birthtypefiveList = [

         {
            name: "نەشتەرگەری",
        }, 
        {
            name: "سروشتی",
        },
    ];
    private birthtypesixList = [

         {
            name: "نەشتەرگەری",
        }, 
        {
            name: "سروشتی",
        },
    ];
    private birthplaceList = [
     {
        name: "دەزگای تەندروستی",
        }, 
        {
        name: "ماڵ",
        },
        {
        name: "هیتر",
        },
];


private birthplacetwoList = [
     {
        name: "دەزگای تەندروستی",
        }, 
        {
        name: "ماڵ",
        },
        {
        name: "هیتر",
        },
];
private birthplacethreeList = [
     {
        name: "دەزگای تەندروستی",
        }, 
        {
        name: "ماڵ",
        },
        {
        name: "هیتر",
        },
];
private birthplacefourList = [
     {
        name: "دەزگای تەندروستی",
        }, 
        {
        name: "ماڵ",
        },
        {
        name: "هیتر",
        },
];
private birthplacefiveList = [
     {
        name: "دەزگای تەندروستی",
        }, 
        {
        name: "ماڵ",
        },
        {
        name: "هیتر",
        },
];
private birthplacesixList = [
     {
        name: "دەزگای تەندروستی",
        }, 
        {
        name: "ماڵ",
        },
        {
        name: "هیتر",
        },
];

private responsibilityList = [
        {
        name: "پزیشک",
        }, 
        {
        name: "مامانی ڕێپێدراو",
        },
        {
        name: "مامان",
        },
];
private responsibilitytwoList = [
        {
        name: "پزیشک",
        }, 
        {
        name: "مامانی ڕێپێدراو",
        },
        {
        name: "مامان",
        },
];
private responsibilitythreeList = [
        {
        name: "پزیشک",
        }, 
        {
        name: "مامانی ڕێپێدراو",
        },
        {
        name: "مامان",
        },
];
private responsibilityfourList = [
        {
        name: "پزیشک",
        }, 
        {
        name: "مامانی ڕێپێدراو",
        },
        {
        name: "مامان",
        },
];
private responsibilityfiveList = [
        {
        name: "پزیشک",
        }, 
        {
        name: "مامانی ڕێپێدراو",
        },
        {
        name: "مامان",
        },
];
private responsibilitysixList = [
        {
        name: "پزیشک",
        }, 
        {
        name: "مامانی ڕێپێدراو",
        },
        {
        name: "مامان",
        },
];
private birthresultList = [
      {
        name: "لەدایک بوی زیندوو",
        }, 
        {
        name: "لەدایک بوی مردو",
        },
        {
        name: "دوگیانی لە دەرەوەی منداڵدان",
        },
        {
        name: "لەبارچوو",
        },
];
private birthresulttwoList = [
      {
        name: "لەدایک بوی زیندوو",
        }, 
        {
        name: "لەدایک بوی مردو",
        },
        {
        name: "دوگیانی لە دەرەوەی منداڵدان",
        },
        {
        name: "لەبارچوو",
        },
];
private birthresultthreeList = [
      {
        name: "لەدایک بوی زیندوو",
        }, 
        {
        name: "لەدایک بوی مردو",
        },
        {
        name: "دوگیانی لە دەرەوەی منداڵدان",
        },
        {
        name: "لەبارچوو",
        },
];
private birthresultfourList = [
      {
        name: "لەدایک بوی زیندوو",
        }, 
        {
        name: "لەدایک بوی مردو",
        },
        {
        name: "دوگیانی لە دەرەوەی منداڵدان",
        },
        {
        name: "لەبارچوو",
        },
];
private birthresultfiveList = [
      {
        name: "لەدایک بوی زیندوو",
        }, 
        {
        name: "لەدایک بوی مردو",
        },
        {
        name: "دوگیانی لە دەرەوەی منداڵدان",
        },
        {
        name: "لەبارچوو",
        },
];
private birthresultsixList = [
      {
        name: "لەدایک بوی زیندوو",
        }, 
        {
        name: "لەدایک بوی مردو",
        },
        {
        name: "دوگیانی لە دەرەوەی منداڵدان",
        },
        {
        name: "لەبارچوو",
        },
];
private educationlevelList = [

        {
        name: "سەرەتای",
        },    
        {
        name: "ئامادەی",
        },
        {
        name: "پەیمانگا",
        },
        {
        name: "زانکۆ",
        },
        {
        name: "ماجستێر",
        },
        {
        name: "دکتۆرا",
        },
        {
        name: "نەخوێندەوار",
        }, 
];



private educationlevelmanList = [

        {
        name: "سەرەتای",
        },    
        {
        name: "ئامادەی",
        },
        {
        name: "پەیمانگا",
        },
        {
        name: "زانکۆ",
        },
        {
        name: "ماجستێر",
        },
        {
        name: "دکتۆرا",
        },
        {
        name: "نەخوێندەوار",
        }, 
];

private patientCard = {
        id: 0,
        storeName: "",
        storeAddress: "",
        name: "",
        dob: "",
        phone: "",
        gender: "",
        issue: "",
        expiry: "",
        patientMother: "",
        patientID: 0,
     };
    private state = reactive({
        firstName: "",
        patientType: "Maternity",
        phoneNo: "",
        gender: "Female",
        dob: "",
        siblings: 0,
        birthOrder: 0,
        bloodType: "Not Known",
        city: "",
        district: 0,
        
    });

    private item = {
        id: 0,
        secondaryPhone: 0,
        yourProfession: "",
        yourEducation: "",
        husbandsName: "",
        husbandsProfession: "",
        husbandEducation: "",
        nextKin: "",
        relationship: "",
        phoneKin: "",
        addressKin: "",
        ageMarried: "",
        pregLengthone: "",
        pregLengthtwo: "",
        pregLengththree: "",
        pregLengthfour: "",
        pregLengthfive: "",
        pregLengthsix: "",
        dateMarriage: "",
        coupleRelated: "",
        numberChildren: 0,
        maleChildren: 0,
        femaleChildren: 0,
        deadChildren: 0,
        firstPeriodDate: "",
        normalPeriod: "Yes",
        periodLasts: 0,
        breastFeed: "No",
        lastChild: "",
        birthTypetwo: "",
        birthTypethree: "",
        birthTypefour: "",
        birthTypefive: "",
        birthTypesix: "",
        forceContraception: "No",
        forceContraceptionNote: "",
        motherName: "",
        houseNo: "0",
        nearestPoint: "0",
        birthDatetwo: "",
        birthDatethree: "",
        birthDatefour: "",
        birthDatefive: "",
        birthDatesix: "",
        birthPlacetwo: "",
        birthPlacethree: "",
        birthPlacefour: "",
        birthPlacefive: "",
        birthPlacesix: "",
        responsibilityTwo: "",
        responsibilityThree: "",
        responsibilityFour: "",
        responsibilityFive: "",
        responsibilitySix: "",
        birthResulttwo: "",
        birthResultthree: "",
        birthResultfour: "",
        birthResultfive: "",
        birthResultsix: "",
        noteText: "",
        forcefullySave: "No",
    };

    private miscarriageList = [
        {
            name: "NO",
        },
        {
            name: "Yes-Pills",
        },
        {
            name: "Yes-Condoms",
        },
        {
            name: "Yes-IUD",
        },
    ];


    private validationRules = {
        firstName: {
            required,
        },
        patientType: {
            required,
        },
        phoneNo: {
            required,
        },
        gender: {
            required,
        },
        dob: {
            required,
        },
        siblings: {
            required,
        },
        birthOrder: {
            required,
        },
        bloodType: {
            required,
        },
        city: {
            required,
        },
        district: {
            required,
        },
    };

private v$ = useVuelidate(this.validationRules, this.state);

    //DEFAULT METHOD OF TYPE SCRIPT
    //CALLING WHENEVER COMPONENT LOADS
    created() {
        this.toast = new Toaster();
        this.patientService = new PatientService();
        this.voucherService = new VoucherService();
    }

    mounted() {
        this.loadPatient();
        this.getTheStoreFilterList();
		// this.productDialog = true;
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }


    closeDialog() {
        this.$emit("updatePatientStatus", {
            patientFullName: this.state.firstName,
            patientID: this.item.id,
        });
        //this.productDialog = false;
        this.submitted = false;
        this.item.forcefullySave = 'No';
    }

    saveItem(isFormValid) {
        this.submitted = true;
        if (isFormValid) {
            if (this.patientID != "0") {
                this.patientService
                    .updateItem(
                        this.patientID,
                        this.state,
                        this.branchId,
                        this.item
                    )
                    .then((res) => {
                        this.closeDialog();
                        this.toast.handleResponse(res);
                    });
            } else {
                this.patientService
                    .saveItem(this.state, this.item)
                    .then((res) => {

                        if(res.patient_no != '')
                        {
                            this.patientCard.name          = res.patient_name;
                            this.patientCard.phone         = res.patient_phone;
                            this.patientCard.patientID     = res.patient_no;
                            this.patientCard.patientMother = res.patient_mother;
                            this.duplicateDialog = true;
                        }
                        else
                        {
                           this.item.id = res.patient_id;
                           this.closeDialog();
                           this.toast.handleResponse(res);
                        }
                    });
            }
        }
    }
    loadPatient() {

		if(this.patientID != '0')
		{
			this.patientService.getItem(this.patientID).then((res) => {
				if (res.length > 0) {
					this.item.id = res[0].id;
					this.state.firstName = res[0].first_name;
					this.state.patientType = res[0].patient_type;
					this.state.phoneNo = res[0].phone_no;
					this.state.gender = res[0].gender;
					this.state.dob = this.formatDate(res[0].dob);
                    this.state.siblings = res[0].siblings;
                    this.state.district = res[0].district;
					this.state.birthOrder = res[0].birth_order;
					this.item.secondaryPhone = Number(res[0].secondary_phone);
					this.state.bloodType = res[0].blood_type;
					this.state.city = res[0].city != 'null' ? res[0].city : "";
                    this.item.houseNo =
						res[0].house_no != 'null' ? res[0].house_no : "0";
                    this.item.nearestPoint =
						res[0].nearest_point != 'null' ? res[0].nearest_point : "";
                    this.item.motherName =
						res[0].mother_name != 'null' ? res[0].mother_name : "";
					this.item.yourProfession =
						res[0].your_profession != 'null' ? res[0].your_profession : "";
					this.item.yourEducation =
						res[0].your_education != 'null' ? res[0].your_education : "";
					this.item.husbandsName = 
                        res[0].husbands_name != 'null' ? res[0].husbands_name : "";
					this.item.husbandsProfession =
						res[0].husbands_profession != 'null' ? res[0].husbands_profession: "";
					this.item.husbandEducation =
						res[0].husband_education != 'null' ? res[0].husband_education: "";
					this.item.nextKin =
						res[0].next_kin != 'null' ? res[0].next_kin : "";
					this.item.relationship =
						res[0].relationship != 'null' ? res[0].relationship : "";
					this.item.phoneKin =
						res[0].phone_kin != 'null' ? res[0].phone_kin : "";
					this.item.addressKin =
						res[0].address_kin != 'null' ? res[0].address_kin : "";
					this.item.ageMarried =
						res[0].age_married != 'null' ? res[0].age_married : "";
                    this.item.pregLengthone =
                    res[0].preg_lengthone != 'null' ? res[0].preg_lengthone : "";
                    this.item.pregLengthtwo =
						res[0].preg_lengthtwo != 'null' ? res[0].preg_lengthtwo : "";
                    this.item.pregLengththree =
						res[0].preg_lengththree != 'null' ? res[0].preg_lengththree : "";
                    this.item.pregLengthfour =
						res[0].preg_lengthfour != 'null' ? res[0].preg_lengthfour : "";
                    this.item.pregLengthfive =
						res[0].preg_lengthfive != 'null' ? res[0].preg_lengthfive : "";
                    this.item.pregLengthsix =
						res[0].preg_lengthsix != 'null' ? res[0].preg_lengthsix : "";
					this.item.dateMarriage =
						res[0].date_marriage != 'null' ? res[0].date_marriage : "";
                    this.item.coupleRelated =
						res[0].couple_related != 'null' ? res[0].couple_related : "";

                    this.item.birthDatetwo =
						res[0].birth_datetwo != 'null' ? res[0].birth_datetwo : "";
                    this.item.birthDatethree =
						res[0].birth_datethree != 'null' ? res[0].birth_datethree : "";
                    this.item.birthDatefour =
						res[0].birth_datefour != 'null' ? res[0].birth_datefour : "";
                    this.item.birthDatefive =
						res[0].birth_datefive != 'null' ? res[0].birth_datefive : "";
                    this.item.birthDatesix =
						res[0].birth_datesix != 'null' ? res[0].birth_datesix : "";
                    

 //                   this.item.coupleRelated= this.formatDate(res[0].couple_related);


					this.item.numberChildren = res[0].number_children;
					this.item.maleChildren = res[0].male_children;
					this.item.femaleChildren = res[0].female_children;
					this.item.deadChildren = res[0].dead_children;
					this.item.firstPeriodDate =
						res[0].first_period_date != 'null' ? res[0].first_period_date: "";
					this.item.normalPeriod =
						res[0].normal_period != 'null' ? res[0].normal_period : "";
					this.item.periodLasts =
						res[0].period_lasts != 'null' ? res[0].period_lasts : "";
					this.item.breastFeed =
						res[0].breast_feed != 'null' ? res[0].breast_feed : "";
					this.item.lastChild =
						res[0].last_child != 'null' ? res[0].last_child : "";
                    this.item.birthTypetwo =
						res[0].birth_typetwo != 'null' ? res[0].birth_typetwo : "";
                    this.item.birthTypethree =
						res[0].birth_typethree != 'null' ? res[0].birth_typethree : "";
                    this.item.birthTypefour =
						res[0].birth_typefour != 'null' ? res[0].birth_typefour : "";
                    this.item.birthTypefive =
						res[0].birth_typefive != 'null' ? res[0].birth_typefive : "";
                    this.item.birthTypesix =
						res[0].birth_typesix != 'null' ? res[0].birth_typesix : "";                        
                        this.item.birthPlacetwo =
						res[0].birth_placetwo != 'null' ? res[0].birth_placetwo : "";
					this.item.birthPlacethree =
						res[0].birth_placethree != 'null' ? res[0].birth_placethree : "";
					this.item.birthPlacefour =
						res[0].birth_placefour != 'null' ? res[0].birth_placefour : "";
					this.item.birthPlacefive =
						res[0].birth_placefive != 'null' ? res[0].birth_placefive : "";
					this.item.birthPlacesix =
						res[0].birth_placesix != 'null' ? res[0].birth_placesix : "";	
					this.item.responsibilityTwo =
						res[0].responsibility_two != 'null' ? res[0].responsibility_two : "";	
					this.item.responsibilityThree =
						res[0].responsibility_three != 'null' ? res[0].responsibility_three : "";
					this.item.responsibilityFour =
						res[0].responsibility_four != 'null' ? res[0].responsibility_four : "";
					this.item.responsibilityFive =
						res[0].responsibility_five != 'null' ? res[0].responsibility_five : "";
					this.item.responsibilitySix =
						res[0].responsibility_six != 'null' ? res[0].responsibility_six : "";						
					this.item.birthResulttwo =
						res[0].birth_resulttwo != 'null' ? res[0].birth_resulttwo : "";
					this.item.birthResultthree =
						res[0].birth_resultthree != 'null' ? res[0].birth_resultthree : "";
					this.item.birthResultfour =
						res[0].birth_resultfour != 'null' ? res[0].birth_resultfour : "";
					this.item.birthResultfive =
						res[0].birth_resultfive != 'null' ? res[0].birth_resultfive : "";
					this.item.birthResultsix =
						res[0].birth_resultsix != 'null' ? res[0].birth_resultsix : "";
                    this.item.noteText =
						res[0].note_text != 'null' ? res[0].note_text : "";
					this.item.forceContraception =
						res[0].force_contraception != 'null' ? res[0].force_contraception: "";
					this.item.forceContraceptionNote =
						res[0].force_contraception_note != 'null' ? res[0].force_contraception_note: "";
					this.branchId = res[0].branch_id;
				}
			});
		}
    }
    formatDate(d) {
        const dt = new Date(d);
        return moment(dt).format("YYYY-MM-DD");
    }

    get dialogTitle() {
        let title = "";

        if (this.patientID == "0") {
            title = "Register New Patient";
        } else {
            title = "Update Patient";
        }

        return title;
    }
    getTheStoreFilterList() {
        this.voucherService.getFilterList().then((res) => {
            this.storeList = res.stores;
        });
    }

    setPatientSex()
    {
       if(this.state.patientType == 'Maternity')
       {
           this.state.gender = 'Female';
       }
    }

    setForceSave(type)
    {
        if(type == 'Yes')
        {
           this.item.forcefullySave = 'Yes';
           this.duplicateDialog = false;
           this.saveItem(true); 
        }
        else
        {
            this.duplicateDialog = false;
            this.submitted = false;
            this.toast.showSuccess('Cancelled Successfully');
        }
    }
   
    get filterGenderType()  //filterGenderType is the value we want to report back when calculated
    {
        //genderTypes is the variable declared
        let l = this.genderTypes;
       
        //patientType is the value of patient type such as maternity or CMO, vac
        if(this.state.patientType == 'Maternity')
        {
           l =  this.genderTypes.filter(e => e.name == "Female");
        // we can have else statement in here as well
        }
        return l;

    
    
    }
  }

