
import { Options, mixins } from "vue-class-component";
import { reactive } from "vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import PatientService from "../../service/PatientService.js";
import Toaster from "../../helpers/Toaster";
import UtilityOptions from "../../mixins/UtilityOptions";
import { useStore,ActionTypes } from "../../store";
import VoucherService from "../../service/VoucherService.js";
import Confirmation from "../../components/Confirmation.vue";



@Options({
    components: {
        Confirmation,
    },
})
export default class Patients extends mixins(UtilityOptions) {
    private componentName = "";
    private lists = [];
    private dialogTitle = "";
    private keyword = "";
    private toast;
    private goToFirstLink = 0;
    private storeList = [];
    private patientService;
    private productDialog = false;
    private duplicateDialog = false;
    private patientDialog = false;
    private movePatientStatus = false;
    private submitted = false;
    private checkPagination = true;
    private totalRecords = 0;
    private limit = 0;
    private voucherService;
    private home = { icon: "pi pi-home", to: "/" };
    private items = [
        { label: "Initialization", to: "initialization" },
        { label: "Patients" },
    ];
    private vuexStore = useStore();

    private barcodeOptions = {
        displayValue: false,
        lineColor: "#000",
        format: "CODE128",
        width: 2,
        height: 40,
        font: "fantasy",
        textAlign: "left",
    };

    private patientCard = {
        id: 0,
        storeName: "",
        storeAddress: "",
        name: "",
        dob: "",
        phone: "",
        gender: "",
        issue: "",
        expiry: "",
        patientMother: "",
        patientID: 0,
    };

    private movePatientObj = {
        branchID: "",
        patientName: "",
        patientID: 0,
    };

     private genderTypes = [
        { name: "Male" },
        { name: "Female" }
    ];

    private bloodList = [
        { name: "Not Known" },
        { name: "A+" },
        { name: "A-" },
        { name: "B+" },
        { name: "B-" },
        { name: "O+" },
        { name: "O-" },
        { name: "AB+" },
        { name: "AB-" },
    ]; 
    
    private patientTypeList = [
        { name: "Maternity" },
        { name: "Child Monitoring" },
        { name: "Vaccination & Others" }
    ];

    private optionList = [
        {
            name: "Yes",
        },
        {
            name: "No",
        },
    ];

    private state = reactive({
        firstName: "",
        patientType: "Maternity",
        phoneNo: "",
        gender: "Female",
        dob: "",
        siblings: 0,
        birthOrder: 0,
        motherName: "",
        secondaryPhone: "",
        bloodType: "Not Known",
        city: "",
        district: "",
        houseNo: "",
        nearestPoint: "",
    });

    private item = {
        id: 0,
        yourProfession: "",
        yourEducation: "",
        husbandsName: "",
        husbandsProfession: "",
        husbandEducation: "",
        nextKin: "",
        relationship: "",
        phoneKin: "",
        addressKin: "",
        ageMarried: "",
        dateMarriage: "",
        coupleRelated: "No",
        numberChildren: 0,
        maleChildren: 0,
        femaleChildren: 0,
        deadChildren: 0,
        firstPeriodDate: "",
        normalPeriod: "",
        periodLasts: 0,
        breastFeed: "No",
        lastChild: "",
        forceContraception: "No",
        forceContraceptionNote: "",
        forcefullySave: "No",
    };

    private validationRules = {
        firstName: {
            required,
        },
        patientType: {
            required,
        },
        phoneNo: {
            required,
        },
        gender: {
            required,
        },
        dob: {
            required,
        },
        siblings: {
            required,
        },
        birthOrder: {
            required,
        },
        motherName: {
            required,
        },
        secondaryPhone: {
            required,
        },
        bloodType: {
            required,
        },
        city: {
            required,
        },
        district: {
            required,
        },
        houseNo: {
            required,
        },
        nearestPoint: {
            required,
        },
    };

    private v$ = useVuelidate(this.validationRules, this.state);

    //CALLING WHEN PAGINATION BUTTON CLICKS
    onPage(event) {
        this.loadList(event.first);
    }

    //DEFAULT METHOD OF TYPE SCRIPT
    created() {
        this.patientService = new PatientService();
        this.voucherService = new VoucherService();
        this.toast = new Toaster();
    }

    //CALLNING AFTER CONSTRUCTOR GET CALLED
    mounted() {
        this.loadList(0);
        this.getTheStoreFilterList();
    }

    //OPEN DIALOG TO ADD NEW ITEM
    openDialog() {
       this.patientDialog = true;
       this.dialogTitle = 'Register New Patient'
    }

    editPatient(id) {
        this.dialogTitle = 'Edit Patient'
        this.patientDialog = true;
        this.patientService.getItem(id).then((res) => {
        if (res.length > 0) {
            this.item.id = res[0].id;
            this.state.firstName = res[0].first_name;
            this.state.patientType = res[0].patient_type;
            this.state.phoneNo = res[0].phone_no;
            this.state.gender = res[0].gender;
            this.state.dob = this.formatDate(res[0].dob);
            this.state.siblings = Number(res[0].siblings);
            this.state.birthOrder = Number(res[0].birth_order);
            this.state.motherName = res[0].mother_name;
            this.state.secondaryPhone = res[0].secondary_phone;
            this.state.bloodType = res[0].blood_type;
            this.state.city = res[0].city != 'null' ? res[0].city : "";
            this.state.district =
                res[0].district != 'null' ? res[0].district : "";
            this.state.houseNo =
                res[0].house_no != 'null' ? res[0].house_no : "";
            this.state.nearestPoint =
                res[0].nearest_point != 'null' ? res[0].nearest_point : "";
            this.item.yourProfession =
                res[0].your_profession != 'null'
                    ? res[0].your_profession
                    : "";
            this.item.yourEducation =
                res[0].your_education != 'null' ? res[0].your_education : "";
            this.item.husbandsName =
                res[0].husbands_name != 'null' ? res[0].husbands_name : "";
            this.item.husbandsProfession =
                res[0].husbands_profession != 'null'
                    ? res[0].husbands_profession
                    : "";
            this.item.husbandEducation =
                res[0].husband_education != 'null'
                    ? res[0].husband_education
                    : "";
            this.item.nextKin =
                res[0].next_kin != 'null' ? res[0].next_kin : "";
            this.item.relationship =
                res[0].relationship != 'null' ? res[0].relationship : "";
            this.item.phoneKin =
                res[0].phone_kin != 'null' ? res[0].phone_kin : "";
            this.item.addressKin =
                res[0].address_kin != 'null' ? res[0].address_kin : "";
            this.item.ageMarried =
                res[0].age_married != 'null' ? res[0].age_married : "";
            this.item.dateMarriage =
                res[0].date_marriage != 'null' ? res[0].date_marriage : "";
            this.item.coupleRelated = res[0].couple_related;
            this.item.numberChildren = res[0].number_children;
            this.item.maleChildren = res[0].male_children;
            this.item.femaleChildren = res[0].female_children;
            this.item.deadChildren = res[0].dead_children;
            this.item.firstPeriodDate =
                res[0].first_period_date != 'null'
                    ? res[0].first_period_date
                    : "";
            this.item.normalPeriod =
                res[0].normal_period != 'null' ? res[0].normal_period : "";
            this.item.periodLasts =
                res[0].period_lasts != 'null' ? res[0].period_lasts : "";
            this.item.breastFeed =
                res[0].breast_feed != 'null' ? res[0].breast_feed : "";
            this.item.lastChild =
                res[0].last_child != 'null' ? res[0].last_child : "";
            this.item.forceContraception =
                res[0].force_contraception != 'null'
                    ? res[0].force_contraception
                    : "";
            this.item.forceContraceptionNote =
                res[0].force_contraception_note != 'null'
                    ? res[0].force_contraception_note
                    : "";
            }
        });
    }

    //FETCH THE DATA FROM SERVER
    loadList(page) {
        this.patientService.getItems(this.keyword, page).then((data) => {
            this.lists = data.records;
            this.totalRecords = data.totalRecords;
            this.limit = data.limit;
        });
    }

    updatePatientStatus(obj) {
        this.componentName = "";
        this.loadList(this.goToFirstLink);
    }

    generatePatientCard(d) {
        this.dialogTitle = "Patient Identity Card";
        this.patientCard.name = d.first_name;
        this.patientCard.storeName = d.branch_details.name;
        this.patientCard.storeAddress = d.branch_details.address;
        this.patientCard.phone = d.phone_no;
        this.patientCard.id = d.id;
        this.patientCard.dob = d.dob;
        this.patientCard.gender = d.gender;
        this.patientCard.issue = d.created_at;
        this.patientCard.expiry = d.created_at;
        this.patientCard.patientID = d.patient_no;
        this.productDialog = true;
    }

    getCompanyURL() {
        return require("@/assets/images/logo.png").default;
    }

    convertString(pin) {
        return String(pin);
    }

    movePatient(data) {
        this.movePatientStatus = true;
        this.movePatientObj.patientID = data.id;
        this.movePatientObj.branchID = data.branch_id;
        this.movePatientObj.patientName =
            data.first_name + " " + data.last_name;
    }

    getTheStoreFilterList() {
        this.voucherService.getFilterList().then((res) => {
            this.storeList = res.stores;
        });
    }

    confirmPatientTransfer()
    {
         this.vuexStore.dispatch(
            ActionTypes.GET_RECEIPT_TITLE,
            "Are you sure to move this patient"
        );
        this.componentName = "Confirmation";
    }

    updateConfirmation(b) {
        this.componentName = "";
        this.movePatientStatus = false;
        if (b.result) {
            this.patientService.movePatient(this.movePatientObj).then((res) => {
                this.toast.handleResponse(res);
                this.loadList(this.goToFirstLink);
            });
        }
    }

     saveItem(isFormValid) {
        this.submitted = true;
        if (isFormValid) {
            if (this.item.id != 0) {
                this.patientService
                .updateItem(
                    this.item.id,
                    this.state,
                    0,
                    this.item
                )
                .then((res) => {
                    this.submitted = false;
                    this.patientDialog = false;
                    this.clearPatient();
                    this.toast.handleResponse(res);
                });
            } else {
                this.patientService
                .saveItem(this.state, this.item)
                .then((res) => {

                    if(res.patient_no != '')
                    {
                        this.patientCard.name          = res.patient_name;
                        this.patientCard.phone         = res.patient_phone;
                        this.patientCard.patientID     = res.patient_no;
                        this.patientCard.patientMother = res.patient_mother;

                        this.duplicateDialog = true;
                    }
                    else
                    {
                        this.submitted = false;
                        this.patientDialog = false;
                        this.clearPatient();
                        this.toast.handleResponse(res);
                    }
                });
            }

            this.loadList(0);
        }
    }

    clearPatient()
    {
        
        this.state.firstName = "";
        this.state.patientType = "Maternity";
        this.state.phoneNo = "";
        this.state.gender = "";
        this.state.dob = "";
        this.state.siblings =  0;
        this.state.birthOrder = 0;
        this.state.motherName = "";
        this.state.secondaryPhone = "";
        this.state.bloodType = "Not Known";
        this.state.city = "";
        this.state.district = "";
        this.state.houseNo = "";
        this.state.nearestPoint = "";
        
        
        this.item = {
            id: 0,
            yourProfession: "",
            yourEducation: "",
            husbandsName: "",
            husbandsProfession: "",
            husbandEducation: "",
            nextKin: "",
            relationship: "",
            phoneKin: "",
            addressKin: "",
            ageMarried: "",
            dateMarriage: "",
            coupleRelated: "No",
            numberChildren: 0,
            maleChildren: 0,
            femaleChildren: 0,
            deadChildren: 0,
            firstPeriodDate: "",
            normalPeriod: "",
            periodLasts: 0,
            breastFeed: "No",
            lastChild: "",
            forceContraception: "No",
            forceContraceptionNote: "",
            forcefullySave: "No",
        };
    }

    generatePrint()
    {
        window.print();
    }

    setPatientSex()
    {
       if(this.state.patientType == 'Maternity')
       {
           this.state.gender = 'Female';
       }
    }

    setForceSave(type)
    {
        if(type == 'Yes')
        {
           this.item.forcefullySave = 'Yes';
           this.duplicateDialog = false;
           this.saveItem(true); 
        }
        else
        {
            this.duplicateDialog = false;
            this.submitted = false;
            this.toast.showSuccess('Cancelled Successfully');
        }
    }
}
