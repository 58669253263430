
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import ProfilerService from "../../service/ProfilerService.js";
import UtilityOptions from "../../mixins/UtilityOptions";
import AutoComplete from "primevue/autocomplete";
import Login from "../login/Login.vue";
import moment from "moment";


interface ITransaction {
  date: string;
  receiptNo: string;
  description: string;
  type: string;
  amount: number;
  payment: number;
  balance: number;
}

interface PreviousTransaction {
  accountId:number;
  totalDebit:number;
  totalCredit: number;
}

@Options({
  components: {AutoComplete},
})

export default class AccountStatement extends mixins(UtilityOptions) {
  private lists : ITransaction [] = [];
  private vouchers : ITransaction [] = [];
  private receipts : ITransaction [] = [];
  private payments : ITransaction [] = [];
  private bankTransactions : ITransaction [] = [];
  private profilerList = [];
  private profilerService;
  private previousList : PreviousTransaction [] = [];
  private reportService;
  private resultTitle = "";
  private previousDate = "";
  private storeName = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Reports", to: "reports" },
    { label: "Account Statement" },
  ];

  private totalBalance = 0;

  private searchFilters = {
    id: "",
    date1: "",
    date2: "",
    filterType: "None",
    customerName: "",
    storeID: 0,
    profileID: 0,
  };

  private reportTypes = [
    {
      name: 'Assets',
    },
    {
      name: 'Liability',
    },
    {
      name: 'Equity',
    },
    {
      name: 'Revenue',
    },
    {
      name: 'Expense',
    },
  ];

  private filterDates = [];
  private dialogTitle;
  private submitted = false;
  private filterBranch = [];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
    this.profilerService = new ProfilerService();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.submitted = false;
    this.dialogTitle = "Filter Report";
    this.productDialog = true;
  }

  storeList()
  {
    this.reportService.getFilterList().then((res) => {
      this.filterBranch  = res.stores;
      this.filterDates   = res.datesList;
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading = true;
    this.reportService.accountStatement(this.searchFilters).then((res) => {
        const data = this.camelizeKeys(res);
        this.resultTitle = data.resultTitle;
        this.previousDate = data.previousDate;
        this.storeName = data.storeName;
        this.lists = data.record;
        this.vouchers = data.vouchers;
        this.receipts = data.receipts;
        this.payments = data.payments;
        this.bankTransactions = data.bankTransactions;
        this.previousList = data.previousRecord;
        this.loading = false;
      //  console.log( this.lists); 

      });
    this.productDialog = false;
  }

  //  get filterAccounts() : IList []  {
  //   let copyList :IList [] = [];

  //   if (this.lists != null) {
  //     copyList = [...this.lists];
  //   }

  //   const uniqueElementsBy = (arr, fn) =>
  //     arr.reduce((acc, v) => {
  //       if (!acc.some((x) => fn(v, x))) acc.push(v);
  //       return acc;
  //     }, []);

  //   const list = uniqueElementsBy(
  //     copyList,
  //     (a, b) => a.accountId == b.accountId
  //   );

  //   return list;
  // }

  

  checkForCredit(a)
  {
    let b = '';

    if(a < 0)
    {
      b = '('+this.formatAmount(Math.abs(a))+')';
    }
    else
    {
      b = this.formatAmount(a);
    }

    return b;
  }

  previousDebit(accountID)
  {
    let total = 0;
    
    const t: PreviousTransaction [] = this.previousList.filter(e => e.accountId == accountID);
    
    if(t.length > 0)
    {
      total = t[0].totalDebit;
    }

    return  total;
  }
  
  previousCredit(accountID)
  {
    let total = 0;
    
    const t: PreviousTransaction [] = this.previousList.filter(e => e.accountId == accountID);
    
    if(t.length > 0)
    {
      total = t[0].totalCredit;
    }

    return  total;
  }
  
  previousBalance(accountID)
  {
    let total = 0;
    
    const t: PreviousTransaction [] = this.previousList.filter(e => e.accountId == accountID);
    
    if(t.length > 0)
    {
      total = t[0].totalDebit -  t[0].totalCredit;
    }

    return  total;
  }


  searchProfiler(event) {
    setTimeout(() => {
      this.profilerService.searchProfiler(event.query.trim()).then((data) => {
        this.profilerList = data.records;
      });
    }, 200);
  }

  saveProfile(event) {
    const profileInfo = event.value;
    this.searchFilters.customerName = profileInfo.account_title;
    this.searchFilters.profileID = profileInfo.id;
  }

  get mixingTransactions(): ITransaction []
  {
    let t : ITransaction [] = [];

    this.lists.forEach(e => {
       t.push({
         date: e.date,
         receiptNo: e.receiptNo,
         description: e.description,
         type: e.type,
         amount: e.amount,
         payment : 0,
         balance: this.totalBalance
       })
    });

    console.log(this.vouchers);

    this.vouchers.forEach(e => {

      let payment = 0;
      let amount  = 0;

      if(e.type == 'EXV')
      {
        amount  = e.payment;
        payment = 0;
      }
      else if(e.type == 'CRV')
      {
        amount  = 0;
        payment = e.payment;
      }
      else if(e.type == 'DBV')
      {
        amount  = 0;
        payment = e.payment;
      }

      t.push({
        date         : e.date,
        receiptNo    : e.receiptNo,
        description  :  e.description,
        type         : e.type,
        amount       : amount,
        payment      : payment,
        balance      : this.totalBalance
      });

    });

    this.receipts.forEach(e => {
       t.push({
         date: e.date,
         receiptNo: e.receiptNo,
         description: e.description,
         type: e.type,
         amount: e.amount,
         payment : 0,
         balance: this.totalBalance
       })
    }); 
    
    this.payments.forEach(e => {
       t.push({
         date: e.date,
         receiptNo: e.receiptNo,
         description: e.description,
         type: e.type,
         amount: 0,
         payment : e.payment,
         balance: this.totalBalance
       })
    });
    
    this.bankTransactions.forEach(e => {
       t.push({
         date: e.date,
         receiptNo: e.receiptNo,
         description: e.description,
         type: e.type,
         amount: 0,
         payment : e.payment,
         balance: this.totalBalance
       })
    });

    t.sort(function (left, right) {
        return moment(left.date).diff(moment(right.date));
    });
    
    return t;
  }

  get getTransactions()
  {
    let a = [...this.mixingTransactions];

    a.forEach(e => {
      this.processAmountBalance(e.type,e.amount);
      e.balance =  this.totalBalance;

      this.processPaymentBalance(e.type,e.payment);
      e.balance =  this.totalBalance;
    });

    console.log(a);
    
    return a;
  }

  processAmountBalance(type,amount)
  {
    if(type == 'INE' || type == 'TRN' || type == 'RPU' || type == 'SLS' || type == 'INV' || type == 'EXV')
    {
      this.totalBalance = this.totalBalance + Number(amount);
    }
    else if(type == 'RFD' || type == 'PUR' || type == 'RFR')
    {
      this.totalBalance = this.totalBalance - Number(amount);
    }
  }

  processPaymentBalance(type,payment)
  {
    if(type == 'INE' || type == 'TRN' || type == 'RPU' || type == 'SLS' || type == 'INV' || type == 'CRV' || type == 'REF')
    {
      this.totalBalance = this.totalBalance - Number(payment);
    }
    else if(type == 'RFD' || type == 'PUR' || type == 'RFR' || type == 'DBV' || type == 'CHQ' || type == 'FTR')
    {
      this.totalBalance = this.totalBalance + Number(payment);
    }
  }
}
