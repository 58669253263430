
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";
import ProfilerService from "../../service/ProfilerService.js";
import UserService from "../../service/UserService.js";
import AutoComplete from "primevue/autocomplete";

@Options({
  components: {AutoComplete},
})

export default class PurchasingReport extends mixins(UtilityOptions) {
  private lists  = [];
  private profilerList = [];
  private userList = [];
  private reportService;
  private profilerService;
  private userService;
  private resultTitle = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Reports", to: "reports" },
    { label: "Stock Receive Report" },
  ];

  private searchFilters = {
    id: "",
    date1: "",
    date2: "",
    filterType: "None",
    storeID: 0,
    type: 'Receive Stock',
    customerID: 0,
    userID: 0,
    brandName: 'All',
    sectorName: 'All',
    categoryName: 'All',
    productType: 'All',
    batchNo: '',
    customerName: 'All',
    UserName: 'All',
  };

  private reportTypes = [
    {
      name: 'Receive Stock',
    },
    {
      name: 'Return Stock'
    },
  ];

  private brands:any = [];
  private sectors:any = [];
  private categories:any = [];
  private productTypes:any = [];

  private filterDates = [];
  private dialogTitle;
  private submitted = false;
  private filterBranch = [];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
    this.profilerService = new ProfilerService();
    this.userService = new UserService();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.submitted = false;
    this.dialogTitle = "Filter Report";
    this.productDialog = true;
  }

  storeList()
  {
    this.reportService.getFilterList().then((res) => {
      this.filterBranch  = res.stores;
      this.filterDates   = res.datesList;
      this.brands        = res.brands;
      this.sectors       = res.brandSector;
      this.categories    = res.categories;
      this.productTypes  = res.productTypes;

      const defaultFilter = {id:0,option_name:'All'};
      this.brands.push(defaultFilter);
      this.sectors.push(defaultFilter);
      this.categories.push(defaultFilter);
      this.productTypes.push(defaultFilter);
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading = true;
    this.reportService.purchaseReport(this.searchFilters).then((res) => {
        const data = this.camelizeKeys(res);
        this.resultTitle = data.resultTitle;
        this.lists = data.record;
        this.loading = false;
      });
    this.productDialog = false;
  }

  searchProfiler(event) {
    setTimeout(() => {
      this.profilerService.searchProfiler(event.query.trim()).then((data) => {
        this.profilerList = data.records;
      });
    }, 200);
  }
  
  searchUser(event) {
    setTimeout(() => {
      this.userService.searchUser(event.query.trim()).then((data) => {
        this.userList = data.records;
      });
    }, 200);
  }

  saveProfile(event) {
    const profileInfo = event.value;
    this.searchFilters.customerName = profileInfo.account_title;
    this.searchFilters.customerID = profileInfo.id;
  }
  
  saveUser(event) {
    const userInfo = event.value;
    this.searchFilters.UserName = userInfo.name;
    this.searchFilters.userID = userInfo.id;
  }
}
