
import { Vue, Options } from "vue-class-component";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";
import { reactive } from "vue";
import PatientConsultationService from "../service/PatientConsultationService";
import PatientService from "../service/PatientService";
import Confirmation from "../components/Confirmation.vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { camelCase } from "lodash";
import moment from "moment";
//import InputNumber from "primevue/inputnumber";

interface recordTypes {
    pDate: Date;
}

@Options({
    components: {
        Confirmation,
    },
})
export default class Assessments extends Vue {
    private recordList: recordTypes[] = [];
    private recordID = 0;
    private pService;
    private submitted = false;
    private patientService;
    private toast;
    private componentName = "";
    private patientType = "";
    private age = 0;
    private gender = "";
    private vuexStore = useStore();

    private assessmentIndicators = {
        bmiStatus: "None",
        bmiValue: 0,
        bmiComment: "",
        o2: "None",
        respiration: "None",
        temperature: "None",
        pressure: "None",
        pulse: "None",
        systolic: "None",
        diastolic: "None",
    };

    private state = reactive({
        weight: 0,
        height: 0,
    });

    private validationRules = {
        weight: {
            required,
        },
        height: {
            required,
        },
    };

    private item = {
        headCircumference: 0,
        waistCircumference: 0,
        temperature: 0,
        systolic: 0,
        diastolic: 0,
        o2: 0,
        respiration: 0,
        pulse: 0,
        pressure: 0,
        babiesSound: 0,
        babyMovement: "",
        agePregnancy: 0,
        swelling: "",
        bellySize: 0,
        ferfolicMedicine: "None",
        ferfolicTabs: 0,
        extraSpace: "",
        checkingBreast: "",
        checkingBelly: "",
        checkingWombArea: "",
        birthReason: "None",
        familyPlanning: "None",
        controlPregnancy: "None",
        miscarriage: "None",
        plavic: "None",
        note: "",
    };

    private v$ = useVuelidate(this.validationRules, this.state);

    private refRanges = {
        bodyTemp: { start: 36.1, end: 37.2 },
        pulseRate: { start: 60, end: 100 },
        oxygen: { start: 95, end: 100 },
        bmi: { start: 18.5, end: 24.9 },
        resRate: { start: 12, end: 18 },
        BPSystolic: { start: 110, end: 120 },
        BPDiastolic: { start: 70, end: 80 },
    };

    private soundList = [
        {
            name: "Normal",
        },
        {
            name: "Abnormal",
        },
      
    ];

    private sizeList = [
        {
            name: "Small",
        },
        {
            name: "Medium",
        },
        {
            name: "Large",
        },
    
    ];

    private ynList = [
        {
            name: "Yes",
        },
        {
            name: "No",
        },
        
    ];



    private SwellList = [
        {
            name: "Yes",
        },
        {
            name: "No",
        },
       
    ];




    private planningList = [
        {
            name: "Economy",
        },
        {
            name: "Health",
        },
        {
            name: "Family decision",
        },
        {
            name: "None",
        },
    ];

    private controlList = [
        {
            name: "Pills",
        },
        {
            name: "Condoms",
        },
        {
            name: "Injection",
        },
        {
            name: "None",
        },
    ];

    private miscarriageList = [
        {
            name: "NO",
        },
        {
            name: "Yes Intentional",
        },
        {
            name: "Yes Uncontrolled",
        },
        {
            name: "None",
        },
    ];

    created() {
        this.pService = new PatientConsultationService();
        this.patientService = new PatientService();
        this.toast = new Toaster();
    }

    mounted() {
        this.loadList();
        this.loadPatientInfo();
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }

    get visitDate() {
        return this.vuexStore.getters.getVisitDate;
    }

    saveItem(isFormValid) {
        this.submitted = true;
        if (isFormValid) {
            this.setStatus();
            this.pService
                .saveAssessment(
                    this.state,
                    this.item,
                    this.patientID,
                    this.assessmentIndicators
                )
                .then((res) => {
                    this.clearItems();
                    this.toast.handleResponse(res);
                    this.loadList();
                });
        }
    }

    clearItems() {
        this.state.weight = 0;
        this.state.height = 0;
        this.item.headCircumference = 0;
        this.item.waistCircumference = 0;
        this.item.temperature = 0;
        this.item.systolic = 0;
        this.item.diastolic = 0;
        this.item.o2 = 0;
        this.item.respiration = 0;
        this.item.pulse = 0;
        this.item.pressure = 0;
        this.item.babiesSound = 0;
        this.item.babyMovement = "";
        this.item.agePregnancy = 0;
        this.item.swelling = "";
        this.item.bellySize = 0;
        this.item.ferfolicMedicine = "None";
        this.item.ferfolicTabs = 0;
        this.item.extraSpace = "";
        this.item.checkingBreast = "";
        this.item.checkingBelly = "";
        this.item.checkingWombArea = "";
        this.item.birthReason = "None";
        this.item.note = "";
        this.assessmentIndicators.bmiValue = 0;
        this.assessmentIndicators.bmiComment = "";
    }

    loadList() {
        this.recordList = [];
        this.pService.getAssessment(this.patientID).then((data) => {
            const res = this.camelizeKeys(data);
            this.recordList = res.records;
            this.patientType = res.patientDetail;
        });
    }

    camelizeKeys = (obj) => {
        if (Array.isArray(obj)) {
            return obj.map((v) => this.camelizeKeys(v));
        } else if (obj !== null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.camelizeKeys(obj[key]),
                }),
                {}
            );
        }
        return obj;
    };

    get sortedRecords() {
        let sortedList = {};

        this.recordList.forEach((e) => {
            const d = moment().format("DD-MMM-YYYY");
            let date = moment(e.pDate).format("DD-MMM-YYYY");

            if (date == d) {
                date = "Today " + date;
            }

            if (sortedList[date]) {
                sortedList[date].push(e);
            } else {
                sortedList[date] = [e];
            }
        });

        return sortedList;
    }

    get getTodaysDate() {
        return "Today " + moment().format("DD-MMM-YYYY");
    }

    deleteItem(id) {
        this.vuexStore.dispatch(
            ActionTypes.GET_RECEIPT_TITLE,
            "Are you sure to delete this"
        );
        this.componentName = "Confirmation";
        this.recordID = id;
    }

    updateConfirmation(b) {
        this.componentName = "";
        if (b.result) {
            this.pService.deleteAssessment(this.recordID).then((res) => {
                this.toast.handleResponse(res);
                this.loadList();
            });
        }
    }

    get respStatus() {
        let status = "None";

        if (this.item.respiration > 0) {
            if (
                this.item.respiration >= this.refRanges.resRate.start &&
                this.item.respiration <= this.refRanges.resRate.end
            ) {
                status = "Normal";
            } else {
                status = "Critical";
            }
        }

        return status;
    }

    get bodyTempStatus() {
        let status = "None";

        if (this.item.temperature > 0) {
            if (
                this.item.temperature >= this.refRanges.bodyTemp.start &&
                this.item.temperature <= this.refRanges.bodyTemp.end
            ) {
                status = "Normal";
            } else {
                status = "Critical";
            }
        }

        return status;
    }
    get pulseRateStatus() {
        let status = "None";

        if (this.item.pulse > 0) {
            if (
                this.item.pulse >= this.refRanges.pulseRate.start &&
                this.item.pulse <= this.refRanges.pulseRate.end
            ) {
                status = "Normal";
            } else {
                status = "Critical";
            }
        }

        return status;
    }

    get oxygenStatus() {
        let status = "None";

        if (this.item.o2 > 0) {
            if (
                this.item.o2 >= this.refRanges.oxygen.start &&
                this.item.o2 <= this.refRanges.oxygen.end
            ) {
                status = "Normal";
            } else {
                status = "Critical";
            }
        }

        return status;
    }

    get bmiStatus() {
        let status = "None";

        if (this.bmiValue > 0) {
            if (
                this.bmiValue >= this.refRanges.bmi.start &&
                this.bmiValue <= this.refRanges.bmi.end
            ) {
                status = "Normal";
            } else {
                status = "Critical";
            }
        }

        return status;
    }

    get BPSystolicStatus() {
        let status = "None";

        if (this.item.systolic > 0) {
            if (
                this.item.systolic >= this.refRanges.BPSystolic.start &&
                this.item.systolic <= this.refRanges.BPSystolic.end
            ) {
                status = "Normal";
            } else {
                status = "Critical";
            }
        }

        return status;
    }

    get BPDiastolicStatus() {
        let status = "None";

        if (this.item.diastolic > 0) {
            if (
                this.item.diastolic >= this.refRanges.BPDiastolic.start &&
                this.item.diastolic <= this.refRanges.BPDiastolic.end
            ) {
                status = "Normal";
            } else {
                status = "Critical";
            }
        }

        return status;
    }

    get calculateBMIStatus() {
        let s = "None";

        if (this.bmiValue > 0) {
            if (this.bmiValue < 18.5) {
                s = "Underweight";
            } else if (this.bmiValue >= 18.5 && this.bmiValue <= 24.9) {
                s = "Normal weight";
            } else if (this.bmiValue >= 25 && this.bmiValue <= 29.9) {
                s = "Overweight";
            } else if (this.bmiValue >= 30) {
                s = "Obesity";
            }
        }

        return s;
    }

    get bmiValue() {
        let a = 0;

        if (this.state.height > 0) {
            const heightMtr = this.state.height / 100;
            a = Number((this.state.weight / Math.pow(heightMtr, 2)).toFixed(2));
        }

        return a;
    }

    get bloodPressure() {
        let g = this.gender;
        let age = this.age;
        let status = "None";

        if (g == "Male" && this.item.pressure > 0) {
            status = "Critical";

            if (
                age >= 15 &&
                age <= 18 &&
                this.item.pressure >= 85 &&
                this.item.pressure <= 120
            ) {
                status = "Normal";
            } else if (
                age >= 19 &&
                age <= 24 &&
                this.item.pressure >= 79 &&
                this.item.pressure <= 120
            ) {
                status = "Normal";
            } else if (
                age >= 25 &&
                age <= 29 &&
                this.item.pressure >= 80 &&
                this.item.pressure <= 121
            ) {
                status = "Normal";
            } else if (
                age >= 30 &&
                age <= 35 &&
                this.item.pressure >= 82 &&
                this.item.pressure <= 123
            ) {
                status = "Normal";
            } else if (
                age >= 36 &&
                age <= 39 &&
                this.item.pressure >= 83 &&
                this.item.pressure <= 124
            ) {
                status = "Normal";
            } else if (
                age >= 40 &&
                age <= 45 &&
                this.item.pressure >= 83 &&
                this.item.pressure <= 125
            ) {
                status = "Normal";
            } else if (
                age >= 46 &&
                age <= 50 &&
                this.item.pressure >= 84 &&
                this.item.pressure <= 127
            ) {
                status = "Normal";
            } else if (
                age >= 51 &&
                age <= 55 &&
                this.item.pressure >= 85 &&
                this.item.pressure <= 128
            ) {
                status = "Normal";
            } else if (
                age >= 56 &&
                age <= 60 &&
                this.item.pressure >= 87 &&
                this.item.pressure <= 131
            ) {
                status = "Normal";
            } else if (
                age > 60 &&
                this.item.pressure >= 88 &&
                this.item.pressure <= 135
            ) {
                status = "Normal";
            }
        } else if (this.item.pressure > 0) {
            status = "Critical";

            if (
                age >= 15 &&
                age <= 18 &&
                this.item.pressure >= 77 &&
                this.item.pressure <= 117
            ) {
                status = "Normal";
            } else if (
                age >= 19 &&
                age <= 24 &&
                this.item.pressure >= 79 &&
                this.item.pressure <= 120
            ) {
                status = "Normal";
            } else if (
                age >= 25 &&
                age <= 29 &&
                this.item.pressure >= 80 &&
                this.item.pressure <= 120
            ) {
                status = "Normal";
            } else if (
                age >= 30 &&
                age <= 35 &&
                this.item.pressure >= 81 &&
                this.item.pressure <= 122
            ) {
                status = "Normal";
            } else if (
                age >= 36 &&
                age <= 39 &&
                this.item.pressure >= 82 &&
                this.item.pressure <= 123
            ) {
                status = "Normal";
            } else if (
                age >= 40 &&
                age <= 45 &&
                this.item.pressure >= 83 &&
                this.item.pressure <= 124
            ) {
                status = "Normal";
            } else if (
                age >= 46 &&
                age <= 50 &&
                this.item.pressure >= 84 &&
                this.item.pressure <= 126
            ) {
                status = "Normal";
            } else if (
                age >= 51 &&
                age <= 55 &&
                this.item.pressure >= 85 &&
                this.item.pressure <= 129
            ) {
                status = "Normal";
            } else if (
                age >= 56 &&
                age <= 60 &&
                this.item.pressure >= 86 &&
                this.item.pressure <= 130
            ) {
                status = "Normal";
            } else if (
                age > 60 &&
                this.item.pressure >= 87 &&
                this.item.pressure <= 134
            ) {
                status = "Normal";
            }
        }

        return status;
    }

    get refBloodPressure() {
        let g = this.gender;
        let age = this.age;
        let ref = "Invalid Reference";

        if (g == "Male") {
            if (age >= 15 && age <= 18) {
                ref = "Age (15 - 18) 85 - 120 mmHg";
            } else if (age >= 19 && age <= 24) {
                ref = "Age (19 - 24) 79 - 120 mmHg";
            } else if (age >= 25 && age <= 29) {
                ref = "Age (25 - 29) 80 - 121 mmHg";
            } else if (age >= 30 && age <= 35) {
                ref = "Age (30 - 35) 82 - 123 mmHg";
            } else if (age >= 36 && age <= 39) {
                ref = "Age (36 - 39) 83 - 124 mmHg";
            } else if (age >= 40 && age <= 45) {
                ref = "Age (40 - 45) 83 - 125 mmHg";
            } else if (age >= 46 && age <= 50) {
                ref = "Age (46 - 50) 84 - 127 mmHg";
            } else if (age >= 51 && age <= 55) {
                ref = "Age (51 - 55) 85 - 128 mmHg";
            } else if (age >= 56 && age <= 60) {
                ref = "Age (56 - 60) 87 - 131 mmHg";
            } else if (age > 60) {
                ref = "Age ( > 60 ) 88 - 135 mmHg";
            }
        } else {
            if (age >= 15 && age <= 18) {
                ref = "Age (15 - 18) 77 - 117 mmHg";
            } else if (age >= 19 && age <= 24) {
                ref = "Age (19 - 24) 79 - 120 mmHg";
            } else if (age >= 25 && age <= 29) {
                ref = "Age (25 - 29) 80 - 120 mmHg";
            } else if (age >= 30 && age <= 35) {
                ref = "Age (30 - 35) 81 - 122 mmHg";
            } else if (age >= 36 && age <= 39) {
                ref = "Age (36 - 39) 82 - 123 mmHg";
            } else if (age >= 40 && age <= 45) {
                ref = "Age (40 - 45) 83 - 124 mmHg";
            } else if (age >= 46 && age <= 50) {
                ref = "Age (46 - 50) 84 - 126 mmHg";
            } else if (age >= 51 && age <= 55) {
                ref = "Age (51 - 55) 85 - 129 mmHg";
            } else if (age >= 56 && age <= 60) {
                ref = "Age (56 - 60) 86 - 130 mmHg";
            } else if (age > 60) {
                ref = "Age ( > 60 ) 87 - 134 mmHg";
            }
        }

        return ref;
    }

    loadPatientInfo() {
        this.patientService.getItem(this.patientID).then((data) => {
            const patientInfo = data[0];
            this.gender = patientInfo.gender;
            this.age = this.patientAge(patientInfo.dob);
        });
    }

    patientAge(d) {
        const dt = new Date(d);
        const years = moment().diff(dt, "years", false);
        return Number(years);
    }

    setStatus() {
        this.assessmentIndicators = {
            bmiStatus: this.bmiStatus,
            bmiValue: this.bmiValue,
            bmiComment: this.calculateBMIStatus,
            o2: this.oxygenStatus,
            respiration: this.respStatus,
            temperature: this.bodyTempStatus,
            pressure: this.bloodPressure,
            pulse: this.pulseRateStatus,
            systolic: this.BPSystolicStatus,
            diastolic: this.BPDiastolicStatus,
        };
    }

    makeFocus(event) {
        event.target.select();
    }

    checkTodaysDate(visitDate) {
        return moment(visitDate).isSame(moment(), "day");
    }

    get minDateValue() {
        return new Date();
    }

    formatDate(d) {
        const dt = new Date(d);
        return moment(dt).format("DD-MMM-YYYY");
    }

    calculateExpectedDate(d)
    {
        if(d != null)
        {
            d = moment(new Date(d)).add(280, 'days');
            d = moment(d).format("DD-MMM-YYYY"); 
        }
        return  d;
    }

    getCurrentWeek(i)
    {
        const a = Number(i.agePregnancy);
        const d = i.pDate;
        const w = Number(moment().diff(d, 'week'));


        return w+a;
    }

    getAgeOfPregnancy(checkingBelly)
    {
        let a = 0;

        if(checkingBelly != null)
        {
            //formatting date
            const lmp = moment(checkingBelly).format("YYYY-MM-DD");

            //Difference in number of days
            const days = moment.duration(moment().diff(lmp)).asDays();

            //applying formula
            a  =   Number(((days + 7 ) / 7).toFixed(0));   
        }

        return a;
    }
}
