
import { Vue, Options } from "vue-class-component";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";
import { reactive } from "vue";
import ReferralService from "../service/ReferralService";
import Confirmation from "../components/Confirmation.vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { camelCase } from "lodash";
import moment from "moment";
import Editor from 'primevue/editor';
import { jsPDF } from "jspdf";
import domtoimage from "dom-to-image-more";

interface recordTypes {
    pDate: Date;
}

@Options({
    components: {
        Confirmation,
        Editor,
    }
})
export default class Referral extends Vue {
    private recordList: recordTypes[] = [];
    private recordID = 0;
    private referralID = 0;
    private pService;
    private submitted = false;
    private letterDialog = false;
    private recommendationDialog = false;
    private toast;
    private componentName = "";
    private patientInfo = {};
    private referralLetterName = "";
    private recommendationText = "";
    private referralLetterNotes = "";
    private vuexStore = useStore();

    private state = reactive({
        referralNotes: '',
    });

    private validationRules = {
        referralNotes: {
            required
        }
    };

    private v$ = useVuelidate(this.validationRules, this.state);

    created() {
        this.pService = new ReferralService();
        this.toast = new Toaster();
    }

    mounted() {
        this.loadList();
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }

    get visitDate() {
        return this.vuexStore.getters.getVisitDate;
    }

    saveItem(isFormValid) {
        this.submitted = true;
        if (isFormValid) {
            this.pService
                .save(this.state, this.patientID)
                .then(res => {
                    this.clearItems();
                    this.toast.handleResponse(res);
                    this.loadList();
                });
        }
    }
    
    saveRecommendation() {
        this.pService
        .saveRecommendation(this.recommendationText, this.referralID)
        .then(res => {
            this.recommendationText = '';
            this.toast.handleResponse(res);
            this.loadList();
        });

        this.recommendationDialog = false;
    }

    clearItems() {
        this.state.referralNotes = '';
        this.submitted = false;
    }

    loadList() {

        this.state.referralNotes = '';

        this.pService.get(this.patientID).then(data => {
            const res = this.camelizeKeys(data);
            this.recordList = res.records;

            if(res.observation.length > 0)
            {
                res.observation.forEach(e => {

                    this.state.referralNotes = this.state.referralNotes + '<b> Chief Complains: </b> ';
                    this.state.referralNotes = this.state.referralNotes + '<br />';
                    this.state.referralNotes = this.state.referralNotes + e.chiefComplaint;
                    this.state.referralNotes = this.state.referralNotes + '<br />';
                    this.state.referralNotes = this.state.referralNotes + '<b> Initial Diagnosis: </b>';
                    this.state.referralNotes = this.state.referralNotes + '<br />';
                    this.state.referralNotes = this.state.referralNotes + e.diagnosis;
                    this.state.referralNotes = this.state.referralNotes + '<br />';
                    
                });
            }
            
            if(res.assessment.length > 0)
            {
                res.assessment.forEach(e => {

                    this.state.referralNotes = this.state.referralNotes + '<b> Examination Finding: </b> ';
                    this.state.referralNotes = this.state.referralNotes + '<br />';
                    this.state.referralNotes = this.state.referralNotes + "BP Systolic (mmHg): "+e.systolic+" BP Diastolic (mmHg): "+e.diastolic+", Pulse: "+e.pulse +", Temp: "+e.temperature+" , RR: "+e.respiration;
                    this.state.referralNotes = this.state.referralNotes + '<br />';
                });
            }

            if(res.consultation.length > 0)
            {
                res.consultation.forEach(e => {

                    this.state.referralNotes = this.state.referralNotes + '<b> Consultations: </b>';
                    this.state.referralNotes = this.state.referralNotes + '<br />';
                    this.state.referralNotes = this.state.referralNotes + e.consultationNotes;
                    this.state.referralNotes = this.state.referralNotes + '<br />';
                    
                });
            }  
            
            if(res.labs.length > 0)
            {
                res.labs.forEach(e => {

                    this.state.referralNotes = this.state.referralNotes + '<b> Investigations: </b>';
                    this.state.referralNotes = this.state.referralNotes + '<br />';
                    this.state.referralNotes = this.state.referralNotes + '<b> Labs: </b> '+'Type : '+e.optionName+' Name : '+e.testName+' Results : '+e.testResult;
                    this.state.referralNotes = this.state.referralNotes + '<br />';
                    
                });   
            }

            this.state.referralNotes = this.state.referralNotes + '<b> Reason for Referral:  </b> ';
            this.state.referralNotes = this.state.referralNotes + '<br />';
            this.state.referralNotes = this.state.referralNotes + '<b> Name and signature of referring physician : _______________________ </b>';
            this.state.referralNotes = this.state.referralNotes + '<br />';
        });

      
        
    }

    camelizeKeys = obj => {
        if (Array.isArray(obj)) {
            return obj.map(v => this.camelizeKeys(v));
        } else if (obj !== null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.camelizeKeys(obj[key])
                }),
                {}
            );
        }
        return obj;
    };

    get sortedRecords() {
        let sortedList = {};

        this.recordList.forEach(e => {
            
            const d = moment().format("DD-MMM-YYYY");
            let date = moment(e.pDate).format("DD-MMM-YYYY");
            
            if (date == d) {
                date = "Today "+date;
            } 

           
            if (sortedList[date]) {
                sortedList[date].push(e);
            } else {
                sortedList[date] = [e];
            }
        });

        return sortedList;
    }

    get getTodaysDate()
    {
        return "Today " + moment().format("DD-MMM-YYYY");
    }

    deleteItem(id) {
        this.vuexStore.dispatch(
            ActionTypes.GET_RECEIPT_TITLE,
            "Are you sure to delete this"
        );
        this.componentName = "Confirmation";
        this.recordID = id;
    }

    updateConfirmation(b) {
        this.componentName = "";
        if (b.result) {
            this.pService.delete(this.recordID).then(res => {
                this.toast.handleResponse(res);
                this.loadList();
            });
        }
    }

    openLetterDialog(i)
    {
        this.patientInfo  = i;
        this.referralLetterNotes = i.referralNotes;
        this.letterDialog = true; 
    }
    
    openRecommendationDialog(i)
    {
        this.recommendationText = '';
        this.recommendationDialog = true; 
        this.referralID = i.id;

        if(i.recommendation != '')
        {
            this.recommendationText =  i.recommendation;
        }
        else
        {
            this.recommendationText = this.recommendationText +  '<p class="p-mt-5" ><b>Referral Report and Recommendations : </b></p>'
            this.recommendationText = this.recommendationText +  '<p class="p-m-1">Date :  </p>'
            this.recommendationText = this.recommendationText +  '<p class="p-m-1"><b>Clinical Finding note :</b></p>'
            this.recommendationText = this.recommendationText +  '<p class="p-m-1"><b>Investigation:</b></p>'
            this.recommendationText = this.recommendationText +  '<p class="p-m-1"> - Lab results:  </p>'
            this.recommendationText = this.recommendationText +  '<p class="p-m-1"> - Rad results:  </p>'
            this.recommendationText = this.recommendationText +  '<p class="p-m-1"><b>Final Diagnosis:</b></p>'
            this.recommendationText = this.recommendationText +  '<p class="p-m-1"><b>Interventions:</b></p>'
            this.recommendationText = this.recommendationText +  '<p class="p-m-1"><b>Recommendation:</b></p>'
        }
    }

    checkTodaysDate(visitDate)
    {
        return  moment(visitDate).isSame(moment(), 'day');
    }

    formatDate(d) {
        const dt = new Date(d);
        return moment(dt).format("DD-MMM-YYYY");
    }

    generatePrint(n)
    {
        let node = document.getElementById("referral_template");
        let t = this;
        domtoimage
        .toPng(node)
        .then(function (dataUrl) {
            const doc = new jsPDF("p", "mm", "a4");
            const imgProps= doc.getImageProperties(dataUrl);
            const pdfWidth = doc.internal.pageSize.getWidth() - 10;
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
           doc.addImage(dataUrl, 'PNG', 5, 5, pdfWidth, pdfHeight);
            doc.save(n+".pdf");
        })
        .catch(function (error) {
            t.toast.showError("Something went wrong " + error);
        });
    }

    closeDialog() {
        this.letterDialog = false;
    }
}
