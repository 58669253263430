
import { Options, Vue } from "vue-class-component";
import StockService from "../../service/StockService.js";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";
import moment from "moment";

@Options({
    components: {},
})
export default class Stocks extends Vue {
    private lists = [];
    private dialogTitle;
    private toast;
    private goToFirstLink = 0;
    private currentStoreID = 0;
    private stockService;
    private productDialog = false;
    private submitted = false;
    private statusDialog = false;
    private keyword = "";
    private checkPagination = true;
    private totalRecords = 0;
    private limit = 0;
    private home = { icon: "pi pi-home", to: "/" };
    private items = [
        { label: "Initialization", to: "initialization" },
        { label: "Stock Management" },
    ];

    private productType;
    private brand;
    private brandSector;
    private category;

    private item = {
        id: 0,
        barcode: "",
        description: "",
        image: "default.jpg",
        sideEffects: "",
        packSelling: 0,
        tax_1: 0,
        tax_2: 0,
        tax_3: 0,
        status: "Active",
    };

    private state = reactive({
        productName: "",
        productType: 0,
        brand: 0,
        brandSector: 0,
        category: 0,
        stripSize: 0,
        packSize: 0,
        batchNo: "",
        unitQty: 0,
        expiryDate: "",
        minStock: 0,
    });

    private validationRules = {
        productName: {
            required,
        },
        productType: {
            required,
        },
        brand: {
            required,
        },
        brandSector: {
            required,
        },
        category: {
            required,
        },
        stripSize: {
            required,
        },
        packSize: {
            required,
        },
        batchNo: {
            required,
        },
        unitQty: {
            required,
        },
        expiryDate: {
            required,
        },
        minStock: {
            required,
        },
    };

    private v$ = useVuelidate(this.validationRules, this.state);

    private taxNames = [
        {
            taxName: "",
            show: false,
            optionalReq: "",
            taxValue: 0,
            accountHead: "",
            accountID: 0,
        },
        {
            taxName: "",
            show: false,
            optionalReq: "",
            taxValue: 0,
            accountHead: "",
            accountID: 0,
        },
        {
            taxName: "",
            show: false,
            optionalReq: "",
            taxValue: 0,
            accountHead: "",
            accountID: 0,
        },
    ];

    private storeList = [];
    private selectedStore = {
        id: 0,
    };

    //CALLING WHEN PAGINATION BUTTON CLICKS
    onPage(event) {
        this.loadList(event.first);
    }

    //DEFAULT METHOD OF TYPE SCRIPT
    //CALLING WHENEVER COMPONENT LOADS
    created() {
        this.stockService = new StockService();
        this.toast = new Toaster();
    }

    //CALLNING AFTER CONSTRUCTOR GET CALLED
    mounted() {
        this.loadList(0);
    }

    //ADD OR UPDATE THE ITEM VIA HTTP
    saveItem(isFormValid) {
        this.submitted = true;
        if (isFormValid) {
            if (this.item.id != 0) {
                this.state.expiryDate = moment(this.state.expiryDate).format(
                    "YYYY-MM-DD"
                );
                this.stockService
                    .updateItem(this.item, this.state)
                    .then((res) => {
                        this.loadList(this.goToFirstLink);
                        //SHOW NOTIFICATION
                        this.toast.handleResponse(res);
                    });
            }

            this.productDialog = false;
            this.clearItems();
        }
    }

    //FETCH THE DATA FROM SERVER
    loadList(page) {
        this.stockService
            .getStocks(this.keyword, this.selectedStore.id, page)
            .then((data) => {
                this.lists = data.records;
                this.totalRecords = data.totalRecords;
                this.limit = data.limit;
                this.storeList = data.stores;
                this.currentStoreID = data.currentStoreID;
                this.productType = data.productType;
                this.brand = data.brand;
                this.brandSector = data.brandSector;
                this.category = data.category;

                // //taxNames
                this.taxNames = [];

                this.taxNames.push({
                    taxName: data.storeTaxes[0].tax_name_1,
                    show: data.storeTaxes[0].show_1,
                    optionalReq: data.storeTaxes[0].required_optional_1,
                    taxValue:
                        data.storeTaxes[0].show_1 == "true"
                            ? Number(data.storeTaxes[0].tax_value_1)
                            : 0,
                    accountHead: data.storeTaxes[0].tax_name1.chartName,
                    accountID: data.storeTaxes[0].link1,
                });

                this.taxNames.push({
                    taxName: data.storeTaxes[0].tax_name_2,
                    show: data.storeTaxes[0].show_2,
                    optionalReq: data.storeTaxes[0].required_optional_2,
                    taxValue:
                        data.storeTaxes[0].show_2 == "true"
                            ? Number(data.storeTaxes[0].tax_value_2)
                            : 0,
                    accountHead: data.storeTaxes[0].tax_name2.chartName,
                    accountID: data.storeTaxes[0].link2,
                });

                this.taxNames.push({
                    taxName: data.storeTaxes[0].tax_name_3,
                    show: data.storeTaxes[0].show_3,
                    optionalReq: data.storeTaxes[0].required_optional_3,
                    taxValue:
                        data.storeTaxes[0].show_3 == "true"
                            ? Number(data.storeTaxes[0].tax_value_3)
                            : 0,
                    accountHead: data.storeTaxes[0].tax_name3.chartName,
                    accountID: data.storeTaxes[0].link3,
                });
            });
    }

    clearItems() {
        this.item = {
            id: 0,
            barcode: "None",
            description: "None",
            image: "default.jpg",
            sideEffects: "None",
            packSelling: 0,
            tax_1: 0,
            tax_2: 0,
            tax_3: 0,
            status: "Active",
        };

        this.state.stripSize = 0;
        this.state.packSize = 0;
        this.state.batchNo = "";
        this.state.unitQty = 0;
        this.state.minStock = 0;
        this.state.expiryDate = "";
    }

    fixDigits(amt) {
        return Number(amt).toFixed(2);
    }

    formatExpiry(date) {
        return moment(date).format("MM-YYYY");
    }

    formatDateTime(date) {
        return moment(date).format("DD-MM-YYYY hh:mm A");
    }

    calculateItemWorth(purchasePrice, qty, packSize) {
        let amount = 0;

        if (packSize > 0) {
            amount = (qty / packSize) * purchasePrice;
        }

        return amount;
    }

    loadSearchData() {
        this.submitted = true;
        if (this.keyword) {
            this.goToFirstLink = 0;
            this.loadList(0);
        }
    }

    editIem(data) {
        this.submitted = false;
        this.dialogTitle = "Update Stock Item";
        this.productDialog = true;

        this.stockService.getItem(data).then((res) => {
            if (res != null) {
                this.item.id = res.id;
                this.item.packSelling = Number(res.sale_price);
                this.item.status = res.status;
                this.item.tax_1 = Number(res.tax_1);
                this.item.tax_2 = Number(res.tax_2);
                this.item.tax_3 = Number(res.tax_3);

                this.item.barcode = res.barcode == null ? "" : res.barcode;
                this.item.description =
                    res.description == null ? "" : res.description;
                this.item.image = res.image;
                this.item.sideEffects =
                    res.side_effects == null ? "" : res.side_effects;

                this.state.productName = res.product_name;
                this.state.packSize = Number(res.pack_size);
                this.state.stripSize = Number(res.strip_size);

                this.state.productType = res.type;
                this.state.brand = res.brand;
                this.state.brandSector = res.brand_sector;
                this.state.category = res.category;
                this.state.batchNo = res.batch_no;
                this.state.unitQty = Number(res.qty);
                this.state.minStock = Number(res.min_stock);
                this.state.expiryDate = res.expiry_date;
            }
        });
    }
}
