
import moment from "moment";
import { Options, Vue } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import BankingService from "../service/BankingService.js";

@Options({
  props: {
    PreviewReceipt: Object,
  },
  watch: {
    PreviewReceipt(obj) {
      
      this.openDialog();
      this.dialogTitle = obj.dialogTitle;
      this.productDialog = obj.status;
      this.previewHeading = obj.previewHeading;

      if(obj.receiptID != 0)
      {
        this.loadReceipt(obj.receiptID);
      }
    },
  },
  emits: ["updatePreviewStatus"],
})
export default class PreviewBankingReceipt extends Vue {
  private toast;
  private productDialog = false;
  private dialogTitle = "";
  private bankingService;
  private previewHeading = '';
  private items = {
    storeName: "",
    storeAddress: "",
    storeEmail: "",
    storePhone: "",
    storeLicense: "",
    receiptNo: "",
    transactionNo: "",
    type: "",
    description: "",
    receiptDate: "",
    bankName: "",
    bankAccount: "",
    selectedProfile: "",
    itemList: [{
      accountName: '',
      amount: 0,
    }]
  };

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.toast = new Toaster();
    this.bankingService = new BankingService();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.productDialog = true;
  }

  closeDialog() {
    this.$emit("updatePreviewStatus", {});
    this.productDialog = false;
  }

  getCompanyURL() 
  {
    return  require('@/assets/images/logo.png').default;
  }

  loadReceipt(receiptID)
  {
    this.bankingService.getReceiptData(receiptID).then((res) => {
      if (res != null) {
        this.items.storeName         = res.storeDetail.name;
        this.items.storeAddress      = res.storeDetail.address;
        this.items.storeEmail        = res.storeDetail.email;
        this.items.storePhone        = res.storeDetail.contact;
        this.items.storeLicense      = res.storeDetail.license_no;
        this.items.receiptNo         = res.receipt.receipt_no;
        this.items.transactionNo     = res.receipt.transaction_no;
        this.items.type              = res.receipt.type;
        this.items.description       = res.receipt.description;
        this.items.receiptDate       = res.receipt.receipt_date;
        this.items.bankName          = res.receipt.bank_name.bankName;
        this.items.bankAccount       = res.receipt.bank_name.bankAccountNo;
        this.items.selectedProfile   = res.receipt.profile_name.profileName;
        
        this.items.itemList = [];
        let account = {accountName: res.receipt.account_head,amount: res.receipt.amount}
        this.items.itemList.push(account);
      }
    });
  }

  fixDigits(amt) {
    return Number(amt).toFixed(2);
  }

  formatDate(date)
  {
    return moment(date).format("DD-MM-YYYY");
  }

  printReceipt() {
    window.print();
	}
}
