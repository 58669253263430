
import { Options, mixins } from "vue-class-component";
import { ref } from "vue";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";

interface IBranch {
    name: string;
}

interface IDrug {
    productName: string;
}

interface IPatient {
    firstName: string;
    patientNo: string;
    patientType: string;
    gender: string;
    dob: string;
    phoneNo: string;
}

interface IReport {
    dose: string;
    doseUnit: string;
    duration: string;
    durationUnit: string;
    frequency: string;
    pDate: string;
    patientDetails: IPatient;
    branchDetails: IBranch;
    drugs: IDrug;
}


interface IFreqType {
    name: string;
    value: number;
}

@Options({
    components: { },
})
export default class MedicationReport extends mixins(UtilityOptions) {
    private dt = ref();
    private lists: IReport[] = [];
    private reportService;
    private resultTitle = "";
    private productDialog = false;
    private loading = false;
    private home = { icon: "pi pi-home", to: "/" };
    private items = [
        { label: "Reports", to: "reports" },
        { label: "Medication Report" },
    ];

    private searchFilters = {
        id: "",
        date1: "",
        date2: "",
        filterType: "None",
        storeID: 0
    };

    private filterDates = [];
    private dialogTitle;
    private submitted = false;
    private filterBranch = [];

    private frequencyLists: IFreqType [] = [
        { name: "One time a day",value: 1 }, 
        { name: "Two time a day",value: 2 },
        { name: "Three time a day",value: 3 },
        { name: "Four time a day",value: 4 },
        { name: "Five time a day",value: 4 },
    ];

    //CALLING WHENEVER COMPONENT LOADS
    created() {
        this.reportService = new StoreReports();
    }

    //CALLNING AFTER CONSTRUCTOR GET CALLED
    mounted() {
        this.storeList();
        this.loadList();
    }

    //OPEN DIALOG TO ADD NEW ITEM
    openDialog() {
        this.submitted = false;
        this.dialogTitle = "Filter Report";
        this.productDialog = true;
    }

    storeList() {
        this.reportService.getFilterList().then((res) => {
            this.filterBranch = res.stores;
            this.filterDates = res.datesList;
        });
    }

    // USED TO GET SEARCHED ASSOCIATE
    loadList() {
        this.loading = true;
        this.reportService.medicationReport(this.searchFilters).then((res) => {
            const data = this.camelizeKeys(res);
            this.resultTitle = data.resultTitle;
            this.lists = data.record;
            this.loading = false;
        });
        this.productDialog = false;
    }

    get rList() {
        const l: IReport[] = [];

        this.lists.forEach((e) => {
            e.pDate = this.formatDate(e.pDate);
            e.dose  = e.dose +' '+ e.doseUnit;
            e.duration  = e.duration +' '+ e.durationUnit;
            e.patientDetails.dob = this.formatDate(e.patientDetails.dob);
            e.frequency   = this.getFrequency(e.frequency);
            l.push(e);
        });

        return l;
    }

    getFrequency(n)
    {
       const d: IFreqType [] =  this.frequencyLists.filter(f => f.value == n);
       
       let t = '';

       if(d.length > 0)
       {
           t = d[0].name; 
       }

       return t;
    }
}
