
import { Vue, Options } from "vue-class-component";
import { useStore, ActionTypes } from "../../store";
import Toaster from "../../helpers/Toaster";
import LabService from "../../service/LabService";
import Confirmation from "../../components/Confirmation.vue";
import { camelCase } from "lodash";

@Options({
    components: {
        Confirmation,
    },
})
export default class LabResult extends Vue {
    private recordList = [];
    private recordID = 0;
    private pService;
    private toast;
    private vuexStore = useStore();
    private keyword = "";
    private componentName = "";
    private labResultTemp = {};
    private home = { icon: "pi pi-home", to: "/" };
     private items = [
        { label: "Initialization", to: "initialization" },
        { label: "Upload Lab Results" },
    ];

    created() {
        this.pService = new LabService();
        this.toast = new Toaster();
    }

    mounted() {
        this.loadList();
    }

    loadList() {
        this.pService.getAllOrderedRequests(this.keyword).then((data) => {
            const res = this.camelizeKeys(data);
            console.log(res.record);
            this.recordList = res.record;
        });
    }

    camelizeKeys = (obj) => {
        if (Array.isArray(obj)) {
            return obj.map((v) => this.camelizeKeys(v));
        } else if (obj !== null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.camelizeKeys(obj[key]),
                }),
                {}
            );
        }
        return obj;
    };

    saveLabResult(d) {
       this.labResultTemp = d;
        this.vuexStore.dispatch(
            ActionTypes.GET_RECEIPT_TITLE,
            "Are you sure to submit this result"
        );
        this.componentName = "Confirmation";
    }

    updateConfirmation(b) {

        this.componentName = "";
        if (b.result) {
            this.pService.saveLabResult(this.labResultTemp).then((res) => {
                this.toast.handleResponse(res);
                this.loadList();
            });
        }
    }
}
