
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";

interface IList {
  accountName: string;
  accountNature: string;
  totalCredit: number;
  totalDebit: number;
}

@Options({
  components: {},
})

export default class TrialBalance extends mixins(UtilityOptions) {
  private lists: IList []  = [];
  private reportService;
  private resultTitle = "";
  private storeName = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Reports", to: "reports" },
    { label: "Trial Balance" },
  ];

  private searchFilters = {
    id: "",
    date1: "",
    date2: "",
    filterType: "this_year",
    storeID: 0,
  };

  private brands:any = [];
  private sectors:any = [];
  private categories:any = [];
  private productTypes:any = [];

  private filterDates = [];
  private dialogTitle;
  private submitted = false;
  private filterBranch = [];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.submitted = false;
    this.dialogTitle = "Filter Report";
    this.productDialog = true;
  }

  storeList()
  {
    this.reportService.getFilterList().then((res) => {
      this.filterBranch  = res.stores;
      this.filterDates   = res.datesList;
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading = true;
    this.reportService.trialBalance(this.searchFilters).then((res) => {
        const data = this.camelizeKeys(res);
        this.resultTitle = data.resultTitle;
        this.storeName = data.storeName;
        this.lists = data.record;
        this.loading = false;
      });
    this.productDialog = false;
  }

  get trialBalanceList()
  {
   this.lists.map(e => {
        const d = e.totalDebit - e.totalCredit;
        const c = e.totalCredit - e.totalDebit;

        if(d > 0)
        {
          e.totalDebit = d;
          e.totalCredit = 0;
        }
        else
        {
          e.totalCredit = c; 
          e.totalDebit = 0;
        }
    });

    return this.lists;
  }

  get sumTotalDebit()
  {
    let total = 0;

    this.lists.forEach(e => {
        total = total + e.totalDebit;
    });

    return total;
  }
  
  get sumTotalCredit()
  {
    let total = 0;

    this.lists.forEach(e => {
        total = total + e.totalCredit;
    });

    return total;
  }
}
