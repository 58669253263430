
import { Options, Vue } from "vue-class-component";
import OpenHeadService from "../../service/OpenHeadService.js";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";
import moment from "moment";

@Options({
  components: {},
})
export default class OpenHead extends Vue {
  private toast;
  private headService;
  private submitted = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Open Account Heads" },
  ];

  private item = {
    id: 0,
    itemList: [
      {
        accountID: 0,
        accountHead: "",
        accountNature: "",
        debitAmount: 0,
        creditAmount: 0,
      },
    ],
  };

  private state = reactive({
    description: "",
  });

  private validationRules = {
    description: {
      required,
    },
  };

  private v$ = useVuelidate(this.validationRules, this.state);

  //DEFAULT METHOD OF TYPE SCRIPT
  created() {
    this.headService = new OpenHeadService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList();
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(isFormValid) {
    this.submitted = true;
    if (isFormValid) {
      this.headService.save(this.item, this.state).then((res) => {
        //SHOW NOTIFICATION
        this.toast.handleResponse(res);
      });
    }
  }

  //FETCH THE DATA FROM SERVER
  loadList() {
    this.headService.getChartHeadList(this.item, this.state).then((data) => {
      const accounts = data.records;

      this.item.itemList = [];

      accounts.map((c) => {
        this.item.itemList.push({
          accountID: Number(c.id),
          accountHead: c.account_name,
          accountNature: c.account_nature,
          debitAmount: 0,
          creditAmount: 0,
        });
      });

      if (data.stored.length > 0) {
        const storedHead = data.stored[0].sub_transaction;
        this.state.description = data.stored[0].narration;

        this.item.itemList.map((c) => {
          storedHead.map((s) => {
            if (c.accountID == Number(s.account_id)) {
              if (s.type == "Debit") {
                c.debitAmount = Number(s.amount);
                c.creditAmount = 0;
              } else {
                c.debitAmount = 0;
                c.creditAmount = Number(s.amount);
              }
            }
          });
        });
      }
    });
  }

  fixDigits(amt) {
    return Number(amt).toFixed(2);
  }

  get totalDebitAmount() {
    let totalAmount = 0;
    this.item.itemList.forEach((v) => {
      totalAmount = totalAmount + Number(v.debitAmount);
    });
    return totalAmount;
  }

  get totalCreditAmount() {
    let totalAmount = 0;
    this.item.itemList.forEach((v) => {
      totalAmount = totalAmount + Number(v.creditAmount);
    });
    return totalAmount;
  }

  get getTheTotalDiff() {
    return this.totalDebitAmount - this.totalCreditAmount;
  }
}
