
import { Vue, Options } from "vue-class-component";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";
import { reactive } from "vue";
import { camelCase } from "lodash";
import moment from "moment";

interface recordTypes {
    pDate: Date;
}

@Options({
    components: {
    }
})
export default class HelpDoc extends Vue {
    private recordList: recordTypes[] = [];
    private recordID = 0;
    private pService;
    private submitted = false;
    private toast;
    private vuexStore = useStore();

  
    created() {
        this.toast = new Toaster();
    }

    mounted() {}
}
