
import { Options, Vue } from "vue-class-component";
import VoucherService from "../../service/VoucherService.js";
import ProfilerService from "../../service/ProfilerService.js";
import ChartService from "../../service/ChartService.js";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import AutoComplete from "primevue/autocomplete";
import SearchFilter from "../../components/SearchFilter.vue";
import PreviewReceipt from "../../components/PreviewReceipt.vue";

@Options({
  components: {
    AutoComplete,
    SearchFilter,
    PreviewReceipt,
  },
})
export default class JournalVoucher extends Vue {
  private lists = [];
  private profilerList = [];
  private accountHeadList = [];
  private statement = "";
  private dialogTitle = "";
  private toast;
  private goToFirstLink = 0;
  private previewHeading = "";
  private receiptID = 0;
  private voucherService;
  private profilerService;
  private chartService;
  private previewImageDialog = false;
  private productDialog = false;
  private filterDialog = false;
  private submitted = false;
  private statusDialog = false;
  private checkPagination = true;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Journal Vouchers" },
  ];

  private itemFilter = {
    keyword: "",
    filterType: "None",
    storeID: 0,
    date1: "",
    date2: "",
    type: "JRV",
  };

  private item = {
    id: 0,
    transactionID: 0,
    status: "Active",
    profileID: 0,
    totalAmount: 0,
    type: "JRV",
  };

  private state = reactive({
    description: "",
    voucherDate: "",
    selectedProfile: "",
    itemList: [
      {
        accountID: 0,
        accountHead: "",
        debitAmount: 0,
        creditAmount: 0,
        totalAmount: 0,
        type: "",
      },
    ],
  });

  private validationRules = {
    description: {
      required,
    },
    voucherDate: {
      required,
    },
    selectedProfile: {
      required,
    },
  };

  private v$ = useVuelidate(this.validationRules, this.state);

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  created() {
    this.voucherService = new VoucherService();
    this.profilerService = new ProfilerService();
    this.chartService = new ChartService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.clearItem();
    this.submitted = false;
    this.dialogTitle = "Create Journal Voucher";
    this.productDialog = true;
  }

  openFilterDialog() {
    this.dialogTitle = "Filter Journal Vouchers";
    this.filterDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(isFormValid) {
    this.submitted = true;
    if (isFormValid && this.validateHeadList.length == 0) {

      this.setAccountingEntries();

      if (this.item.id != 0) {
        this.state.voucherDate = moment(this.state.voucherDate).format(
          "YYYY-MM-DD"
        );

        this.voucherService.update(this.item, this.state).then((res) => {
          this.loadList(this.goToFirstLink);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.state.voucherDate = moment(this.state.voucherDate).format(
          "YYYY-MM-DD"
        );

        this.voucherService.save(this.item, this.state).then((res) => {
          this.goToFirstLink = 0;
          this.loadList(this.goToFirstLink);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.clearItem();
    }
  }
  

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Journal Voucher";
    this.productDialog = true;

    this.voucherService.getItem(data).then((res) => {
      if (res != null) {
        this.item.id = res.voucher.id;
        this.state.selectedProfile = res.voucher.profile_name;
        this.state.voucherDate = res.voucher.voucher_date;
        this.state.description = res.voucher.memo;
        this.item.profileID = res.voucher.profile_id;
        this.item.transactionID = res.voucher.transaction_id;
        this.item.totalAmount = res.voucher.total_amount;
        this.item.type = res.voucher.type;
        this.item.status = res.voucher.status;

        let vList = res.voucherList;

        if (vList.length > 0) {
          this.state.itemList = [];
          vList.map((v) => {
            if (v.type == "Debit") {
              this.state.itemList.push({
                accountID: Number(v.account_id),
                accountHead: v.account_name,
                debitAmount: Number(v.amount),
                creditAmount: 0,
                totalAmount: Number(v.amount),
                type: v.type,
              });
            } else {
              this.state.itemList.push({
                accountID: Number(v.account_id),
                accountHead: v.account_name,
                debitAmount: 0,
                creditAmount: Number(v.amount),
                totalAmount: Number(v.amount),
                type: v.type,
              });
            }
          });
        }
      }
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.voucherService.getItems(this.itemFilter, page).then((data) => {
      this.lists = data.records;
      this.totalRecords = data.totalRecords;
      this.limit = data.limit;
      this.statement = data.statement;
    });
  }

  clearItem() {
    this.item.id = 0;
    this.item.profileID = 0;
    this.item.status = "Active";
    this.state.description = "";
    this.state.voucherDate = "";
    this.state.selectedProfile = "";

    this.state.itemList = [];
    this.state.itemList.push({
      accountID: 0,
      accountHead: "",
      debitAmount: 0,
      creditAmount: 0,
      totalAmount: 0,
      type: "",
    });
    this.state.itemList.push({
      accountID: 0,
      accountHead: "",
      debitAmount: 0,
      creditAmount: 0,
      totalAmount: 0,
      type: "",
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.itemFilter.keyword) {
      this.goToFirstLink = 0;
      this.loadList(this.goToFirstLink);
    }
  }

  searchProfiler(event) {
    setTimeout(() => {
      this.profilerService.searchProfiler(event.query.trim()).then((data) => {
        this.profilerList = data.records;
      });
    }, 500);
  }

  saveProfile(event) {
    const profileInfo = event.value;
    this.state.selectedProfile = profileInfo.account_title;
    this.item.profileID = profileInfo.id;
  }

  searchAccountHead(event) {
    setTimeout(() => {
      this.chartService.searchAccountHead(event.query.trim()).then((data) => {
        this.accountHeadList = data.records;
      });
    }, 500);
  }

  saveAccountHead(event, data) {
    const accountInfo = event.value;
    data.accountHead = accountInfo.account_name;
    data.accountID = accountInfo.id;
  }

  addNewLine() {
    this.state.itemList.push({
      accountID: 0,
      accountHead: "",
      debitAmount: 0,
      creditAmount: 0,
      totalAmount: 0,
      type: "",
    });
  }

  clearLines() {
    this.state.itemList = [];
    this.state.itemList.push({
      accountID: 0,
      accountHead: "",
      debitAmount: 0,
      creditAmount: 0,
      totalAmount: 0,
      type: "",
    });

    this.state.itemList.push({
      accountID: 0,
      accountHead: "",
      debitAmount: 0,
      creditAmount: 0,
      totalAmount: 0,
      type: "",
    });

    this.toast.showSuccess("All Items Deleted Successfully");
  }

  clearListItem(item) {
    this.state.itemList.splice(this.state.itemList.indexOf(item), 1);
    this.toast.showSuccess("Item Deleted Successfully");
  }

  get totalDebitAmount() {
    let totalAmount = 0;
    this.state.itemList.forEach((v) => {
      totalAmount = totalAmount + Number(v.debitAmount);
    });

    this.item.totalAmount = totalAmount;

    return totalAmount;
  }

  get totalCreditAmount() {
    let totalAmount = 0;
    this.state.itemList.forEach((v) => {
      totalAmount = totalAmount + Number(v.creditAmount);
    });
    return totalAmount;
  }

  get getTheTotalDiff() {
    return this.totalDebitAmount - this.totalCreditAmount;
  }

  get validateHeadList() {
    let invalidListItems: Number[] = [];

    this.state.itemList.map((v, index) => {
      if (v.accountID == 0) {
        invalidListItems.push(index);
      }
    });

    return invalidListItems;
  }

  fixDigits(amt) {
    return Number(amt).toFixed(2);
  }

  formatDate(date) {
    return moment(date).format("DD-MM-YYYY");
  }

  formatDateTime(date) {
    return moment(date).format("DD-MM-YYYY hh:mm A");
  }

  updateFilterStatus(obj) {
    if (obj != null && obj.loading == "Yes") {
      this.itemFilter.filterType = obj.filterName.value;
      this.itemFilter.storeID = obj.storeID.id;
      this.itemFilter.date1 = obj.date1;
      this.itemFilter.date2 = obj.date2;
      this.itemFilter.keyword = "";
      this.goToFirstLink = 0;
      this.loadList(this.goToFirstLink);
    }
    this.filterDialog = false;
  }

  openPreviewDialog(data) {
    this.previewImageDialog = true;
    this.dialogTitle = "Preview Journal Voucher";
    this.previewHeading = "Journal Voucher";
    this.receiptID = data.id;
  }

  updatePreviewStatus() {
    this.previewImageDialog = false;
  }

   setAccountingEntries() {

     if(this.item.type == 'JRV')
     {
       this.state.itemList.forEach(e => {
         if(e.debitAmount != 0)
         {
           e.type = 'Debit';
           e.totalAmount = e.debitAmount;
         }
         else if(e.creditAmount != 0)
         {
           e.type = 'Credit';
           e.totalAmount = e.creditAmount;
         }
       });
     }
  }
}
